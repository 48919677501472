// Todo: Look at removing/moving this
p-table {
  thead {
    z-index: 5 !important;
  }
}

// Todo: Look at removing/moving this
p-dropdown.sort-options {
  .p-inputtext {
    padding-right: 0 !important;
  }

  .p-dropdown-trigger {
    width: 2rem !important;
  }
}

// Todo: Look at removing/moving this
button.p-button {
  white-space: nowrap;
}

// Todo: Look at removing/moving this
eyeicon,
eyeslashicon {
  right: 1.5rem;
}

// Todo: Look at removing/moving this
.submission-container {
  .p-galleria-item-nav {
    background: rgba(0, 0, 0, 0.5) !important;
    color: #fcfdfd !important;
    z-index: 100 !important;
    top: 2.5% !important;
  }
}

// Todo: Look at removing/moving this
p-sidebar {
  .disable-scroll {
    .p-sidebar-content {
      overflow-y: hidden !important;
    }
  }
}

// Todo: Look at removing/moving this
ic-layout-default-sidebar {
  .p-sidebar-header {
    display: none !important;
  }
}

// Todo: Look at removing/moving this
.markerjs-toolbar {
  background-color: #ef7715 !important;
  z-index: 1 !important;
}

//zoom container magnifying glass
// Todo: Look at removing/moving this
.pz-zoom-button.pz-zoom-control-position-bottom {
  bottom: 10px !important;
  right: 10px !important;
  left: unset !important;
}

// Todo: Remove need for this - used by hard coded forms
.positive-button {
  margin: 10px;
  background-color: $el-bg-primary;
}

// Todo: This is used but I want to remove
.loading-shade {
  position: fixed;
  text-align: center;
  top: 30%;
  left: calc(50% - 50px);
}

// Todo: Look at removing/moving this
.pdf-thumbnail div {
  max-width: 3.5rem !important;
}

// Todo: Look at removing/moving this
.cdk-overlay-container {
  position: fixed;
}

// Todo: Look at removing/moving this
.mobile-action-buttons .p-sidebar-header {
  padding: 0 !important;
}

// Todo: Look at removing/moving this
.mobile-action-buttons .p-sidebar-content {
  padding: 0.5rem !important;
}

// Todo: Look at removing/moving this
.mobile-action-buttons .p-sidebar-content button {
  padding: 0.75rem !important;
}

// Todo: Look at removing/moving this
.patient-list-card .p-card-content {
  padding: 0 !important;
}

// Todo: Look at removing/moving this
.file-upload-spacing {
  right: 10rem !important;
}

// Todo: Look at removing/moving this
.clinician-submission-tabview {
  .p-tabview-nav-container {
    position: sticky;
    z-index: 15;
    top: 0;
  }

  .p-tabview-nav-toolbar {
    top: 55px !important;
    z-index: 15;
  }
}

// Todo: Check if this can be removed
.image-preview-no-click {
  .p-image-preview-indicator {
    pointer-events: none;

    button {
      pointer-events: auto;
    }
  }
}

// Todo: Check if this can be removed
.p-tabview-nav-link {
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

// Todo: Check if this can be removed
.deselect-entry-close-btn {
  padding: 0 !important;
}

// Todo: Check if this can be removed
.patient-record-folder-list {
  .p-tree-filter-container {
    padding: 0 0.75rem !important;

    .p-tree-filter-icon {
      display: none;
    }
  }
}

// Todo: Check if this can be removed
.p-button {
  span {
    text-wrap: nowrap;
  }
}
