@tailwind components;
@layer components {
  .p-button {
    @apply text-lg leading-[1.375rem];
  }
  .message-error {
    @apply flex-x gap-2 items-center self-stretch text-error border-rounded border-error-subtle bg-error-subtle-alt p-3;
  }
  .message-success {
    @apply flex-x gap-2 items-center self-stretch text-success border-rounded border-success-subtle bg-success-subtle-alt p-3;
  }
  .message-warning {
    @apply flex-x gap-2 items-center self-stretch text-warning border-rounded border-warning-subtle bg-warning-subtle-alt p-3;
  }
  .message-info {
    @apply flex-x gap-2 items-center self-stretch text-action border-rounded border-action-subtle bg-action-subtle-alt p-3;
  }
  .p-panel {
    &.p-panel-toggleable {
      .p-toggleable-content {
        @apply hidden;
      }

      &.p-panel-expanded {
        .p-toggleable-content {
          @apply block;
        }
      }
    }
  }

  div[class^='intercom-with-namespace-'] {
    &.intercom-hidden {
      @apply z-0;
    }
  }
}
