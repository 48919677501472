$text-main: #0b0c0c;
$text-secondary: #505a5f;
$text-disabled: #666f73;
$text-link: #1a66a8;
$text-link-hover: #003078;
$text-link-visited: #4c2c92;
$text-error: #c23019;
$text-warning: #897306;
$text-success: #35824f;
$text-brand: #ef6706;
$text-main-inverse: #fcfcfc;
$text-secondary-inverse: #f3f2f1;
$text-overlay-subtle: rgba(124, 131, 135, 0.5);

// borders
$border-main: #d3d6d7;
$border-alt: #a7acaf;
$border-alt-2: #7c8387;
$border-brand: #e56000;
$border-brand-subtle: #ffbf7f;
$border-error: #c23019;
$border-warning: #f9dd4f;
$border-success: #35824f;
$border-overlay-subtle: rgba(124, 131, 135, 0.5);

// backgrounds
$bg-surface-white: #fff;
$bg-main: #fcfcfc;
$bg-main-surface: #f8f8f8;
$bg-main-surface-alt: #f3f2f1;
$bg-inverse-main: #2e3336;
$bg-inverse-main-surface: #1d2021;
$bg-inverse-main-surface-alt: #0b0c0c;
$bg-overlay: #2e333699;
$bg-overlay-subtle: rgba(124, 131, 135, 0.5);

// element backgrounds
$el-bg-disabled: #d3d6d7;
$el-bg-primary: #e56000;
$el-bg-primary-hover: #cc5600;
$el-bg-primary-pressed: #b34b00;
$el-bg-primary-subtle: #ffe5cc;
$el-bg-primary-subtle-alt: #fff3e6;
$el-bg-primary-light: #ffbf7f;
$el-bg-action: #1a66a8;
$el-bg-action-hover: #003078;
$el-bg-action-pressed: #002256;
$el-bg-action-subtle: #cce5ff;
$el-bg-action-subtle-alt: #e5f2ff;
$el-bg-error: #c23019;
$el-bg-error-hover: #9f2815;
$el-bg-error-pressed: #6a1b0e;
$el-bg-error-subtle: #ffdfdf;
$el-bg-error-subtle-alt: #ffecec;
$el-bg-warning-subtle: #ffffcc;
$el-bg-warning-subtle-alt: #ffffe5;
$el-bg-success: #327b4c;
$el-bg-success-hover: #255a37;
$el-bg-success-pressed: #132d1b;
$el-bg-success-subtle: #ccffdd;
$el-bg-success-subtle-alt: #e5ffee;
