@import 'colours';

.render-error {
  font-family: 'Inter', sans-serif;
  z-index: -1000;
  display: none;

  max-width: 100%;
  width: 600px;

  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;

  box-shadow: 0px 0px 20px 0px $bg-overlay-subtle;
  border-radius: 1rem;
}
.render-error-header {
  background: $text-brand;
  color: $text-main-inverse;
  padding: 1rem;
}
.render-error-footer {
  background: $bg-main;
  padding: 1rem;

  p:first-of-type {
    margin-top: 0;
  }
}
.render-error-stack p {
  font-family: 'Courier New', Courier, monospace;
  font-size: 0.8rem;
  font-weight: 600;
  margin-bottom: 0;

  color: $text-error;
}
.render-error-stack p:last-of-type {
  color: $text-link;
}
.close-error-button {
  color: $text-main-inverse;
  text-decoration: underline;
  cursor: pointer;
  position: fixed;
  font-size: 0.8rem;
  right: 10px;
  top: 10px;
}
