@mixin scroll-shadow($background-color) {
  background:
    linear-gradient($background-color 33%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), $background-color 66%) 0 100%,
    radial-gradient(farthest-side at 50% 0, rgba(102, 102, 102, 0.5), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 50% 100%, rgba(102, 102, 102, 0.5), rgba(0, 0, 0, 0)) 0 100% !important;
  background-color: $background-color !important;
  background-repeat: no-repeat !important;
  background-attachment: local, local, scroll, scroll !important;
  background-size:
    100% 18px,
    100% 18px,
    100% 6px,
    100% 6px !important;
}
