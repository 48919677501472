@import 'for_size';

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.select-resource-checkbox .mat-checkbox-frame {
  border-color: white !important;
  border-width: 0.2rem !important;
}
.mat-mdc-button,
.mdc-button,
.mat-button {
  font-family: Roboto, 'Helvetica Neue', sans-serif !important;
  font-size: 14px !important;
  padding: 0 16px !important;
  height: 36px !important;
  .mat-icon {
    margin-right: 0.25rem !important;
    font-size: 24px !important;
    height: 24px !important;
    width: 24px !important;
  }
}
.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
  padding: 0 !important;
  font-size: 0.75rem;
}
.mat-mdc-card {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  display: block;
  position: relative;
  padding: 16px;
  border-radius: 4px;
}
.mat-dialog-container {
  padding: 24px !important;
}
.mdc-notched-outline {
  border-color: $border-brand !important;
}
mat-checkbox {
  .mdc-checkbox__checkmark {
    color: $text-main;
  }
}
.mat-step-icon {
  color: $text-main-inverse !important;
}
.submit-button {
  .mat-mdc-button:not(:disabled) {
    color: $text-main-inverse !important;
    background-color: $el-bg-primary !important;
  }
}
button.mat-mdc-button-base {
  text-transform: uppercase;
}
.mat-drawer-container {
  z-index: 2;
}
.summary-section-dashboard .mat-mdc-paginator {
  background: transparent;
}

.space-end-pagination .mat-mdc-paginator-container {
  padding-right: 50px !important;
}

.mat-mdc-select-panel .mat-mdc-optgroup-label {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -5px !important;
}

.mat-mdc-menu-panel {
  max-height: calc(50vh) !important;
}
.mat-tooltip-font-15 {
  font-size: 15px;
}

.mat-mdc-select-panel.virtual-scroll {
  max-height: 100% !important;
  overflow: inherit !important;
}

.mat-mdc-select-panel .cdk-virtual-scroll-viewport {
  max-height: 240px !important;
}

.mat-mdc-select-panel .cdk-virtual-scroll-content-wrapper {
  position: inherit !important;
  top: inherit !important;
  left: 0;
}
.cdk-overlay-container,
.cdk-overlay-pane {
  z-index: 9999 !important;
}

.filter-row {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  font-size: 14px;
  margin-bottom: 10px;

  @include for-size(phone-only) {
    mat-form-field {
      width: 100%;
    }
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper {
    margin: 0;
    padding: 0;
  }
}
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-button-disabled {
  cursor: not-allowed;

  &:active {
    pointer-events: none;
  }
}

.outcome-measure-dialog {
  .mat-mdc-tab-group.mat-primary .mat-ink-bar,
  .mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: #ef7715;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
.entries-compare .mat-chip-list-wrapper {
  overflow: auto;
  flex-wrap: initial;
}

/* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
.entries-compare .mat-chip-list-wrapper {
  overflow: auto;
  flex-wrap: initial;
}

.teamConfigForm {
  .mat-mdc-progress-spinner circle,
  .mat-mdc-progress-spinner circle {
    stroke: #ffffff !important;
  }
}

.teamConfigForm,
.organisationForm {
  .mat-mdc-progress-spinner circle,
  .mat-mdc-progress-spinner circle {
    stroke: #ffffff !important;
  }
}

.bespoke-calc-dialog.mobile-dialog {
  .mat-horizontal-stepper-header-container,
  .mat-mdc-dialog-title {
    display: none !important;
  }

  .mat-horizontal-content-container {
    padding: 0 !important;
  }

  .mdc-card {
    box-shadow: none !important;
    padding-bottom: 0;
  }

  .clinical-form-wrapper-field-group {
    margin-bottom: 0 !important;
  }

  .p-dialog-content {
    padding: 0 !important;
    padding-bottom: 5rem !important;
    background: $bg-surface-white !important;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.select-resource-checkbox .mat-checkbox-frame {
  border-color: white !important;
  border-width: 0.2rem !important;
}

.resource-dialog-checkbox {
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .mat-checkbox-layout {
    white-space: normal !important;
    text-align: left;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .mat-checkbox-inner-container {
    margin-top: 0.25rem;
  }
}

.automatic-form-array-table {
  .mat-mdc-form-field {
    width: 100% !important;
    max-width: unset !important;
  }
}

mat-option {
  mat-pseudo-checkbox {
    display: none !important;
  }
}
