@tailwind utilities;
@layer utilities {
  .flex-x {
    @apply flex flex-row;
  }
  .flex-y {
    @apply flex flex-col;
  }
  .icon {
    @apply font-mat-icon text-3xl text-textMain;
    &-filled {
      font-variation-settings:
        'FILL' 1,
        'wght' 300,
        'GRAD' -25,
        'opsz' 24;
    }
    &-outlined {
      font-variation-settings:
        'FILL' 0,
        'wght' 300,
        'GRAD' -25,
        'opsz' 24;
    }
  }

  .border {
    @apply border-borderColour;

    &-t-solid {
      border-top-style: solid;
      @apply border-t;
    }

    &-b-solid {
      border-bottom-style: solid;
      @apply border-b;
    }

    &-rounded {
      @apply border border-solid rounded;
    }

    &-focus {
      @apply border-2 border-solid border-brandSubtle;
    }
  }

  .fixed-height-minus-header {
    min-height: calc(100vh - 56px);
    max-height: calc(100vh - 56px);
  }

  .checkbox-border {
    @apply border-rounded border-borderColour bg-bgMainSurfaceAlt p-2.5;
  }

  .wrap-long-text {
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-word;
  }

  .button-reset {
    @apply bg-transparent border-0 p-0 m-0 shadow-none;
  }

  .interactive-link {
    @apply font-bold underline underline-offset-4 leading-3 cursor-pointer;
  }
}
