@tailwind base;
@layer base {
  h1 {
    @apply font-semibold text-5xl leading-[3rem];
  }
  h2 {
    @apply font-semibold text-4xl leading-[2.5rem];
  }
  h3 {
    @apply font-semibold text-3xl leading-[2rem];
  }
  h4 {
    @apply text-2xl leading-[1.75rem];
  }
  h5 {
    @apply text-lg leading-[1.5rem];
  }
  // We ignore .patient-submission-container here so that the patient submission isn't affected by smaller text
  label,
  a,
  p {
    &:not(.patient-submission-container *, .p-button *, .p-placeholder *, p-badge *, .banner-sm *) {
      @apply text-base leading-[1.25rem];
    }
  }
  span {
    @apply text-base;
  }
  label {
    &:not(.p-menuitem-link *, .p-checkbox *) {
      @apply font-semibold;
    }
  }
  small {
    @apply text-sm;
  }
}
