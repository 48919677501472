:root {
  --surface: #f8f8f8;
  --surface-alt: #f3f2f1;
  --surface-a: #ffffff;
  --surface-b: #f8f9fa;
  --surface-c: #e9ecef;
  --surface-d: #dee2e6;
  --surface-e: #ffffff;
  --surface-f: #ffffff;
  --text-color: #495057;
  --text-color-secondary: #6c757d;
  --color-action: #1a66a8;
  --color-success: #35824f;
  --color-success-subtle: #ccffdd;
  --color-error: #c23019;
  --color-error-subtle: #ffdfdf;
  --color-primary: #e56000;
  --color-primary-subtle: #ffe5cc;
  --color-brand-subtle-alt: #fff3e6;
  --color-brand-subtle: #ffbf7f;
  --color-alt-2: #7c8387;
  --primary-color: #2196f3;
  --primary-color-text: #ffffff;
  --color-pink: #f13ca9;
  --color-pink-subtle: #ffdcf5;
  --color-purple: #7b61ff;
  --color-purple-subtle: #ebe7ff;
  --font-family: 'Inter', sans-serif;
  --surface-0: #ffffff;
  --surface-50: #fafafa;
  --surface-100: #f5f5f5;
  --surface-200: #eeeeee;
  --surface-300: #e0e0e0;
  --surface-400: #bdbdbd;
  --surface-500: #9e9e9e;
  --surface-600: #757575;
  --surface-700: #616161;
  --surface-800: #424242;
  --surface-900: #212121;
  --content-padding: 0.75rem 1rem;
  --inline-spacing: 0.5rem;
  --border-radius: 6px;
  --surface-ground: #f8f9fa;
  --surface-section: #ffffff;
  --surface-card: #ffffff;
  --surface-overlay: #ffffff;
  --surface-border: #dee2e6;
  --surface-hover: #e9ecef;
  --maskbg: rgba(46, 51, 54, 0.6);
  --focus-ring: 0 0 0 0.2rem #ffbf7f;
  --focus-border: #fde4d2;
  --border-main: #d3d6d7;
  color-scheme: light;
  --action-subtle-alt: #e5f2ff;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src:
    url('./fonts/Inter-Regular.woff2') format('woff2'),
    url('./fonts/Inter-Regular.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src:
    url('./fonts/Inter-Medium.woff2') format('woff2'),
    url('./fonts/Inter-Medium.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src:
    url('./fonts/Inter-SemiBold.woff2') format('woff2'),
    url('./fonts/Inter-SemiBold.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  /* Changed from 600 to 700 for "Inter Bold" */
  src:
    url('./fonts/Inter-Bold.woff2') format('woff2'),
    url('./fonts/Inter-Bold.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: 'IBM Plex Mono';
  src: url('./fonts/IBMPlexMono-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
* {
  box-sizing: border-box;
}

.p-component {
  font-family: 'Inter', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
}

.p-component-overlay {
  background-color: rgba(46, 51, 54, 0.6);
  transition-duration: 0.2s;
}

.p-disabled,
.p-component:disabled {
  opacity: 0.5;
}

.p-error {
  color: #e24c4c;
}

.p-text-secondary {
  color: #505a5f;
}

.pi {
  font-size: 1rem;
}

.p-link {
  font-size: 0.875rem;
  font-family: 'Inter', sans-serif;
  border-radius: 6px;
}
.p-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
}

.p-component-overlay-enter {
  animation: p-component-overlay-enter-animation 150ms forwards;
}

.p-component-overlay-leave {
  animation: p-component-overlay-leave-animation 150ms forwards;
}

@keyframes p-component-overlay-enter-animation {
  from {
    background-color: transparent;
  }
  to {
    background-color: var(--maskbg);
  }
}
@keyframes p-component-overlay-leave-animation {
  from {
    background-color: var(--maskbg);
  }
  to {
    background-color: transparent;
  }
}

.p-autocomplete .p-autocomplete-loader {
  right: 0.75rem;
}
.p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
  right: 3.75rem;
}
.p-autocomplete .p-autocomplete-multiple-container {
  padding: 0.375rem 0.75rem;
}
.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {
  border-color: #ffbf7f;
}
.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
  border-color: #ffbf7f;
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
  padding: 0.375rem 0;
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
  font-family: 'Inter', sans-serif;
  font-size: 0.875rem;
  color: #0b0c0c;
  padding: 0;
  margin: 0;
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
  padding: 0.375rem 0.75rem;
  margin-right: 0.5rem;
  background: #ffe5cc;
  color: #0b0c0c;
  border-radius: 6px;
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
  margin-left: 0.5rem;
}

p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext {
  border-color: #c23019;
}

.p-autocomplete-panel {
  background: #fff;
  color: #0b0c0c;
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.p-autocomplete-panel .p-autocomplete-items {
  padding: 0.75rem 0 0 0;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  margin: 0;
  padding: 0.75rem 1rem;
  border: 0 none;
  color: #0b0c0c;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 0;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
  color: #0b0c0c;
  background: #f3f2f1;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
  color: #0b0c0c;
  background: #ffe5cc;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-empty-message {
  padding: 0.75rem 1rem;
  color: #0b0c0c;
  background: transparent;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group {
  margin: 0;
  padding: 0.75rem 1.25rem;
  color: #0b0c0c;
  background: #fff;
  font-weight: 600;
}

p-autocomplete.p-autocomplete-clearable .p-inputtext {
  padding-right: 2.5rem;
}
p-autocomplete.p-autocomplete-clearable .p-autocomplete-clear-icon {
  color: #0b0c0c;
  right: 0.75rem;
}

p-autocomplete.p-autocomplete-clearable .p-autocomplete-dd .p-autocomplete-clear-icon {
  color: #0b0c0c;
  right: 3.75rem;
}

p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext {
  border-color: #c23019;
}

.p-datepicker {
  padding: 0.5rem;
  background: #fff;
  color: #0b0c0c;
  border: transparent;
  border-radius: 6px;
}
.p-datepicker:not(.p-datepicker-inline) {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
  background: #fff;
}
.p-datepicker .p-datepicker-header {
  padding: 0.5rem;
  color: #0b0c0c;
  background: #fff;
  font-weight: 600;
  margin: 0;
  border-bottom: transparent;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  width: 2rem;
  height: 2rem;
  color: #0b0c0c;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
  color: #0b0c0c;
  border-color: transparent;
  background: #f8f8f8;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
}
.p-datepicker .p-datepicker-header .p-datepicker-title {
  line-height: 2rem;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  color: #0b0c0c;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
  font-weight: 600;
  padding: 0.5rem;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover {
  color: #e56000;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin-right: 0.5rem;
}
.p-datepicker table {
  font-size: 0.875rem;
  margin: 0.5rem 0;
}
.p-datepicker table th {
  padding: 0.5rem;
}
.p-datepicker table th > span {
  width: 2.5rem;
  height: 2.5rem;
}
.p-datepicker table td {
  padding: 0.5rem;
}
.p-datepicker table td > span {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  transition: box-shadow 0.2s;
  border: 1px solid transparent;
}
.p-datepicker table td > span.p-highlight {
  color: #0b0c0c;
  background: #ffe5cc;
}
.p-datepicker table td > span:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
}
.p-datepicker table td.p-datepicker-today > span {
  background: #ffe5cc;
  color: #0b0c0c;
  border-color: transparent;
}
.p-datepicker table td.p-datepicker-today > span.p-highlight {
  color: #0b0c0c;
  background: #ffe5cc;
}
.p-datepicker .p-datepicker-buttonbar {
  padding: 1rem 0;
  border-top: 1px solid #dee2e6;
}
.p-datepicker .p-datepicker-buttonbar .p-button {
  width: auto;
}
.p-datepicker .p-timepicker {
  border-top: 1px solid #dee2e6;
  padding: 0.5rem;
}
.p-datepicker .p-timepicker button {
  width: 2rem;
  height: 2rem;
  color: #0b0c0c;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
}
.p-datepicker .p-timepicker button:enabled:hover {
  color: #0b0c0c;
  border-color: transparent;
  background: #f8f8f8;
}
.p-datepicker .p-timepicker button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
}
.p-datepicker .p-timepicker button:last-child {
  margin-top: 0.2em;
}
.p-datepicker .p-timepicker span {
  font-size: 1.25rem;
}
.p-datepicker .p-timepicker > div {
  padding: 0 0.5rem;
}
.p-datepicker.p-datepicker-timeonly .p-timepicker {
  border-top: 0 none;
}
.p-datepicker .p-monthpicker {
  margin: 0.5rem 0;
}
.p-datepicker .p-monthpicker .p-monthpicker-month {
  padding: 0.5rem;
  transition: box-shadow 0.2s;
  border-radius: 6px;
}
.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
  color: #0b0c0c;
  background: #ffe5cc;
}
.p-datepicker .p-yearpicker {
  margin: 0.5rem 0;
}
.p-datepicker .p-yearpicker .p-yearpicker-year {
  padding: 0.5rem;
  transition: box-shadow 0.2s;
  border-radius: 6px;
}
.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
  color: #0b0c0c;
  background: #ffe5cc;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
  border-left: 1px solid #dee2e6;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-top: 0;
  padding-bottom: 0;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
  padding-left: 0;
  border-left: 0 none;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
  padding-right: 0;
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
  background: #ffe5cc;
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover {
  background: #ffe5cc;
}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
}
.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
  background: #ffe5cc;
}
.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
}

p-calendar.p-calendar-clearable .p-inputtext {
  padding-right: 2.5rem;
}
p-calendar.p-calendar-clearable .p-calendar-clear-icon {
  color: #0b0c0c;
  right: 0.75rem;
}

p-calendar.p-calendar-clearable.p-calendar-w-btn .p-calendar-clear-icon {
  color: #0b0c0c;
  right: 3.75rem;
}

@media screen and (max-width: 769px) {
  .p-datepicker table th,
  .p-datepicker table td {
    padding: 0;
  }
}
.p-cascadeselect {
  background: #ffffff;
  border: 1px solid #d3d6d7;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
  border-radius: 6px;
}
.p-cascadeselect:not(.p-disabled):hover {
  border-color: #ffbf7f;
}
.p-cascadeselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
  border-color: #ffbf7f;
}
.p-cascadeselect .p-cascadeselect-label {
  background: transparent;
  border: 0 none;
  padding: 0.75rem 0.75rem;
}
.p-cascadeselect .p-cascadeselect-label.p-placeholder {
  color: #505a5f;
}
.p-cascadeselect .p-cascadeselect-label:enabled:focus {
  outline: 0 none;
  box-shadow: none;
}
.p-cascadeselect .p-cascadeselect-trigger {
  background: transparent;
  color: #0b0c0c;
  width: 3rem;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.p-cascadeselect-panel {
  background: #fff;
  color: #0b0c0c;
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.p-cascadeselect-panel .p-cascadeselect-items {
  padding: 0.75rem 0 0 0;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item {
  margin: 0;
  border: 0 none;
  color: #0b0c0c;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 0;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content {
  padding: 0.75rem 1rem;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #ffbf7f;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight {
  color: #0b0c0c;
  background: #ffe5cc;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: #0b0c0c;
  background: #f3f2f1;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-group-icon {
  font-size: 0.875rem;
}

p-cascadeselect.ng-dirty.ng-invalid > .p-cascadeselect {
  border-color: #c23019;
}

.p-input-filled .p-cascadeselect {
  background: #f8f9fa;
}
.p-input-filled .p-cascadeselect:not(.p-disabled):hover {
  background-color: #f3f2f1;
}
.p-input-filled .p-cascadeselect:not(.p-disabled).p-focus {
  background-color: #f3f2f1;
}

p-cascadeselect.p-cascadeselect-clearable .p-cascadeselect-label {
  padding-right: 0.75rem;
}
p-cascadeselect.p-cascadeselect-clearable .p-cascadeselect-clear-icon {
  color: #0b0c0c;
  right: 3rem;
}

.p-checkbox {
  width: 24px;
  height: 24px;
}
.p-checkbox .p-checkbox-box {
  border: 2px solid #d3d6d7;
  background: #ffffff;
  width: 24px;
  height: 24px;
  color: #0b0c0c;
  border-radius: 6px;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
}
.p-checkbox .p-checkbox-box .p-checkbox-icon {
  transition-duration: 0.2s;
  color: #fcfcfc;
  font-size: 14px;
}
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #e56000;
  background: #e56000;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #ffbf7f;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
  border-color: #ffbf7f;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #cc5600;
  background: #cc5600;
  color: #fcfcfc;
}

p-checkbox.ng-dirty.ng-invalid > .p-checkbox > .p-checkbox-box {
  border-color: #c23019;
}

.p-input-filled .p-checkbox .p-checkbox-box {
  background-color: #f8f9fa;
}
.p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
  background: #e56000;
}
.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  background-color: #f3f2f1;
}
.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  background: #cc5600;
}

.p-checkbox-label {
  margin-left: 0.5rem;
}

.p-chips .p-chips-multiple-container {
  padding: 0.375rem 0.75rem;
}
.p-chips .p-chips-multiple-container:not(.p-disabled):hover {
  border-color: #ffbf7f;
}
.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
  border-color: #ffbf7f;
}
.p-chips .p-chips-multiple-container .p-chips-token {
  padding: 0.375rem 0.75rem;
  margin-right: 0.5rem;
  background: #ffe5cc;
  color: #0b0c0c;
  border-radius: 6px;
}
.p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
  margin-left: 0.5rem;
}
.p-chips .p-chips-multiple-container .p-chips-input-token {
  padding: 0.375rem 0;
}
.p-chips .p-chips-multiple-container .p-chips-input-token input {
  font-family: 'Inter', sans-serif;
  font-size: 0.875rem;
  color: #0b0c0c;
  padding: 0;
  margin: 0;
}

p-chips.ng-dirty.ng-invalid > .p-chips > .p-inputtext {
  border-color: #c23019;
}

p-chips.p-chips-clearable .p-inputtext {
  padding-right: 1.75rem;
}
p-chips.p-chips-clearable .p-chips-clear-icon {
  color: #0b0c0c;
  right: 0.75rem;
}

.p-colorpicker-preview,
.p-fluid .p-colorpicker-preview.p-inputtext {
  width: 2rem;
  height: 2rem;
}

.p-colorpicker-panel {
  background: #323232;
  border: 1px solid #191919;
}
.p-colorpicker-panel .p-colorpicker-color-handle,
.p-colorpicker-panel .p-colorpicker-hue-handle {
  border-color: #f8f8f8;
}

.p-colorpicker-overlay-panel {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.p-dropdown {
  background: #ffffff;
  border: 1px solid #d3d6d7;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
  border-radius: 6px;
}
.p-dropdown:not(.p-disabled):hover {
  border-color: #ffbf7f;
}
.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
  border-color: #ffbf7f;
}
.p-dropdown.p-dropdown-clearable .p-dropdown-label {
  padding-right: 1.75rem;
}
.p-dropdown .p-dropdown-label {
  background: transparent;
  border: 0 none;
}
.p-dropdown .p-dropdown-label.p-placeholder {
  color: #505a5f;
}
.p-dropdown .p-dropdown-label:enabled:focus {
  outline: 0 none;
  box-shadow: none;
}
.p-dropdown .p-dropdown-trigger {
  background: transparent;
  color: #0b0c0c;
  width: 3rem;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.p-dropdown .p-dropdown-clear-icon {
  color: #0b0c0c;
  right: 3rem;
}

.p-dropdown-panel {
  background: #fff;
  color: #0b0c0c;
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.p-dropdown-panel .p-dropdown-header {
  padding: 0.75rem;
  border-bottom: 1px solid #d3d6d7;
  color: #0b0c0c;
  background: #f8f8f8;
  margin: 0;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  padding-right: 1.75rem;
  margin-right: -1.75rem;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
  right: 0.75rem;
  color: #0b0c0c;
}
.p-dropdown-panel .p-dropdown-items {
  padding: 0.75rem 0 0 0;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  margin: 0;
  padding: 0.75rem 1rem;
  border: 0 none;
  color: #0b0c0c;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 0;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #0b0c0c;
  background: #ffe5cc;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  color: #0b0c0c;
  background: #f3f2f1;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
  padding: 0.75rem 1rem;
  color: #0b0c0c;
  background: transparent;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
  margin: 0;
  padding: 0.75rem 1.25rem;
  color: #0b0c0c;
  background: #fff;
  font-weight: 600;
}

p-dropdown.ng-dirty.ng-invalid > .p-dropdown {
  border-color: #c23019;
}

.p-input-filled .p-dropdown {
  background: #f8f9fa;
}
.p-input-filled .p-dropdown:not(.p-disabled):hover {
  background-color: #f3f2f1;
}
.p-input-filled .p-dropdown:not(.p-disabled).p-focus {
  background-color: #f3f2f1;
}
.p-input-filled .p-dropdown:not(.p-disabled).p-focus .p-inputtext {
  background-color: transparent;
}

.p-editor-container .p-editor-toolbar {
  background: #f8f8f8;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-editor-container .p-editor-toolbar.ql-snow {
  border: #d3d6d7;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-stroke {
  stroke: #0b0c0c;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-fill {
  fill: #0b0c0c;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label {
  border: 0 none;
  color: #0b0c0c;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover {
  color: #0b0c0c;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-stroke {
  stroke: #0b0c0c;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-fill {
  fill: #0b0c0c;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: #0b0c0c;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  stroke: #0b0c0c;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  fill: #0b0c0c;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background: #fff;
  border: 0 none;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 0.75rem 0 0 0;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
  color: #0b0c0c;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item:hover {
  color: #0b0c0c;
  background: #f3f2f1;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded:not(.ql-icon-picker) .ql-picker-item {
  padding: 0.75rem 1rem;
}
.p-editor-container .p-editor-content {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-editor-container .p-editor-content.ql-snow {
  border: #d3d6d7;
}
.p-editor-container .p-editor-content .ql-editor {
  background: #ffffff;
  color: #0b0c0c;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-editor-container .ql-snow.ql-toolbar button:hover,
.p-editor-container .ql-snow.ql-toolbar button:focus {
  color: #0b0c0c;
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-stroke {
  stroke: #0b0c0c;
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-fill,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-fill {
  fill: #0b0c0c;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
  color: #e56000;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
  stroke: #e56000;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
  fill: #e56000;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-picker-label {
  color: #e56000;
}

.p-inputgroup-addon {
  background: #fff;
  color: #0b0c0c;
  border-top: 1px solid #d3d6d7;
  border-left: 1px solid #d3d6d7;
  border-bottom: 1px solid #d3d6d7;
  padding: 0.75rem 0.75rem;
  min-width: 3rem;
}
.p-inputgroup-addon:last-child {
  border-right: 1px solid #d3d6d7;
}

.p-inputgroup > .p-component,
.p-inputgroup > .p-element,
.p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext,
.p-inputgroup > .p-float-label > .p-component {
  border-radius: 0;
  margin: 0;
}
.p-inputgroup > .p-component + .p-inputgroup-addon,
.p-inputgroup > .p-element + .p-inputgroup-addon,
.p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext + .p-inputgroup-addon,
.p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {
  border-left: 0 none;
}
.p-inputgroup > .p-component:focus,
.p-inputgroup > .p-element:focus,
.p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext:focus,
.p-inputgroup > .p-float-label > .p-component:focus {
  z-index: 1;
}
.p-inputgroup > .p-component:focus ~ label,
.p-inputgroup > .p-element:focus ~ label,
.p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext:focus ~ label,
.p-inputgroup > .p-float-label > .p-component:focus ~ label {
  z-index: 1;
}

.p-inputgroup-addon:first-child,
.p-inputgroup button:first-child,
.p-inputgroup input:first-child,
.p-inputgroup > .p-inputwrapper:first-child > .p-component,
.p-inputgroup > .p-inputwrapper:first-child > .p-component > .p-inputtext {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.p-inputgroup .p-float-label:first-child input {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.p-inputgroup-addon:last-child,
.p-inputgroup button:last-child,
.p-inputgroup input:last-child,
.p-inputgroup > .p-inputwrapper:last-child > .p-component,
.p-inputgroup > .p-inputwrapper:last-child > .p-component > .p-inputtext {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.p-inputgroup .p-float-label:last-child input {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.p-fluid .p-inputgroup .p-button {
  width: auto;
}
.p-fluid .p-inputgroup .p-button.p-button-icon-only {
  width: 3rem;
}

p-inputmask.ng-dirty.ng-invalid > .p-inputtext {
  border-color: #c23019;
}

p-inputmask.p-inputmask-clearable .p-inputtext {
  padding-right: 2.5rem;
}
p-inputmask.p-inputmask-clearable .p-inputmask-clear-icon {
  color: #0b0c0c;
  right: 0.75rem;
}

p-inputnumber.ng-dirty.ng-invalid > .p-inputnumber > .p-inputtext {
  border-color: #c23019;
}

p-inputnumber.p-inputnumber-clearable .p-inputnumber-input {
  padding-right: 2.5rem;
}
p-inputnumber.p-inputnumber-clearable .p-inputnumber-clear-icon {
  color: #0b0c0c;
  right: 0.75rem;
}

p-inputnumber.p-inputnumber-clearable .p-inputnumber-buttons-stacked .p-inputnumber-clear-icon {
  right: 3.75rem;
}
p-inputnumber.p-inputnumber-clearable .p-inputnumber-buttons-horizontal .p-inputnumber-clear-icon {
  right: 3.75rem;
}

.p-inputswitch {
  width: 3.5rem;
  height: 2rem;
}
.p-inputswitch .p-inputswitch-slider {
  background: #d3d6d7;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
  border-radius: 40px;
}
.p-inputswitch .p-inputswitch-slider:before {
  background: #f8f8f8;
  width: 1.5rem;
  height: 1.5rem;
  left: 0.25rem;
  margin-top: -0.75rem;
  border-radius: 50%;
  transition-duration: 0.2s;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  transform: translateX(1.5rem);
}
.p-inputswitch.p-focus .p-inputswitch-slider {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
}
.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
  background: #bdc1c3;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #e56000;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background: #f8f8f8;
}
.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background: #cc5600;
}

p-inputswitch.ng-dirty.ng-invalid > .p-inputswitch {
  border-color: #c23019;
}

.p-inputtext {
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  color: #0b0c0c;
  background: #ffffff;
  padding: 0.75rem 0.75rem;
  border: 1px solid #d3d6d7;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
  appearance: none;
  border-radius: 6px;
}
.p-inputtext:enabled:hover {
  border-color: #ffbf7f;
}
.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
  border-color: #ffbf7f;
}
.p-inputtext.ng-dirty.ng-invalid {
  border-color: #c23019;
}
.p-inputtext.p-inputtext-sm {
  font-size: 0.875rem;
  padding: 0.65625rem 0.65625rem;
}
.p-inputtext.p-inputtext-lg {
  font-size: 1.25rem;
  padding: 0.9375rem 0.9375rem;
}

.p-float-label > label {
  left: 0.75rem;
  color: #505a5f;
  transition-duration: 0.2s;
}

.p-float-label > .ng-invalid.ng-dirty + label {
  color: #c23019;
}

.p-input-icon-left > i:first-of-type {
  left: 0.75rem;
  color: #0b0c0c;
}

.p-input-icon-left > .p-inputtext {
  padding-left: 2.5rem;
}

.p-input-icon-left.p-float-label > label {
  left: 2.5rem;
}

.p-input-icon-right > i:last-of-type {
  right: 0.75rem;
  color: #0b0c0c;
}

.p-input-icon-right > .p-inputtext {
  padding-right: 2.5rem;
}

::-webkit-input-placeholder {
  color: #505a5f;
}

:-moz-placeholder {
  color: #505a5f;
}

::-moz-placeholder {
  color: #505a5f;
}

:-ms-input-placeholder {
  color: #505a5f;
}

.p-input-filled .p-inputtext {
  background-color: #f8f9fa;
}
.p-input-filled .p-inputtext:enabled:hover {
  background-color: #f3f2f1;
}
.p-input-filled .p-inputtext:enabled:focus {
  background-color: #f3f2f1;
}

.p-inputtext-sm .p-inputtext {
  font-size: 0.875rem;
  padding: 0.65625rem 0.65625rem;
}

.p-inputtext-lg .p-inputtext {
  font-size: 1.25rem;
  padding: 0.9375rem 0.9375rem;
}

.p-listbox {
  background: #ffffff;
  color: #0b0c0c;
  border: transparent;
  border-radius: 6px;
}
.p-listbox .p-listbox-header {
  padding: 0.75rem;
  border-bottom: 1px solid #d3d6d7;
  color: #0b0c0c;
  background: #f8f9fa;
  margin: 0;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-listbox .p-listbox-header .p-listbox-filter {
  padding-right: 1.75rem;
}
.p-listbox .p-listbox-header .p-listbox-filter-icon {
  right: 0.75rem;
  color: #0b0c0c;
}
.p-listbox .p-listbox-header .p-checkbox {
  margin-right: 0.5rem;
}
.p-listbox .p-listbox-list {
  padding: 0.75rem 0 0 0;
}
.p-listbox .p-listbox-list .p-listbox-item {
  margin: 0;
  padding: 0.75rem 1rem;
  border: 0 none;
  color: #0b0c0c;
  transition: box-shadow 0.2s;
  border-radius: 0;
}
.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
  color: #0b0c0c;
  background: #ffe5cc;
}
.p-listbox .p-listbox-list .p-listbox-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #ffbf7f;
}
.p-listbox .p-listbox-list .p-listbox-item .p-checkbox {
  margin-right: 0.5rem;
}
.p-listbox .p-listbox-list .p-listbox-item-group {
  margin: 0;
  padding: 0.75rem 1.25rem;
  color: #0b0c0c;
  background: #fff;
  font-weight: 600;
}
.p-listbox .p-listbox-list .p-listbox-empty-message {
  padding: 0.75rem 1rem;
  color: #0b0c0c;
  background: transparent;
}
.p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
  color: #0b0c0c;
  background: #f3f2f1;
}

p-listbox.ng-dirty.ng-invalid > .p-listbox {
  border-color: #c23019;
}

.p-multiselect {
  background: #ffffff;
  border: 1px solid #d3d6d7;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
  border-radius: 6px;
}
.p-multiselect:not(.p-disabled):hover {
  border-color: #ffbf7f;
}
.p-multiselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
  border-color: #ffbf7f;
}
.p-multiselect .p-multiselect-label {
  padding: 0.75rem 0.75rem;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
}
.p-multiselect .p-multiselect-label.p-placeholder {
  color: #505a5f;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 0.375rem 0.75rem;
  margin-right: 0.5rem;
  background: #ffe5cc;
  color: #0b0c0c;
  border-radius: 6px;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
  margin-left: 0.5rem;
}
.p-multiselect .p-multiselect-trigger {
  background: transparent;
  color: #0b0c0c;
  width: 3rem;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.p-inputwrapper-filled .p-multiselect.p-multiselect-chip .p-multiselect-label {
  padding: 0.375rem 0.75rem;
}

.p-multiselect-panel {
  background: #fff;
  color: #0b0c0c;
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.p-multiselect-panel .p-multiselect-header {
  padding: 0.75rem;
  border-bottom: 1px solid #d3d6d7;
  color: #0b0c0c;
  background: #f8f8f8;
  margin: 0;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
  padding-right: 1.75rem;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
  right: 0.75rem;
  color: #0b0c0c;
}
.p-multiselect-panel .p-multiselect-header .p-checkbox {
  margin-right: 0.5rem;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
  margin-left: 0.5rem;
  width: 2rem;
  height: 2rem;
  color: #0b0c0c;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
  color: #0b0c0c;
  border-color: transparent;
  background: #f8f8f8;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
}
.p-multiselect-panel .p-multiselect-items {
  padding: 0.75rem 0 0 0;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  margin: 0;
  padding: 0.75rem 1rem;
  border: 0 none;
  color: #0b0c0c;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 0;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #0b0c0c;
  background: #ffe5cc;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: #0b0c0c;
  background: #f3f2f1;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #ffbf7f;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
  margin-right: 0.5rem;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
  margin: 0;
  padding: 0.75rem 1.25rem;
  color: #0b0c0c;
  background: #fff;
  font-weight: 600;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
  padding: 0.75rem 1rem;
  color: #0b0c0c;
  background: transparent;
}

p-multiselect.ng-dirty.ng-invalid > .p-multiselect {
  border-color: #c23019;
}

.p-input-filled .p-multiselect {
  background: #f8f9fa;
}
.p-input-filled .p-multiselect:not(.p-disabled):hover {
  background-color: #f3f2f1;
}
.p-input-filled .p-multiselect:not(.p-disabled).p-focus {
  background-color: #f3f2f1;
}

p-multiselect.p-multiselect-clearable .p-multiselect-label-container {
  padding-right: 1.75rem;
}
p-multiselect.p-multiselect-clearable .p-multiselect-clear-icon {
  color: #0b0c0c;
  right: 3rem;
}

p-password.ng-invalid.ng-dirty > .p-password > .p-inputtext {
  border-color: #c23019;
}

.p-password-panel {
  padding: 0.75rem 1rem;
  background: #f8f8f8;
  color: #0b0c0c;
  border: 0 none;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}
.p-password-panel .p-password-meter {
  margin-bottom: 0.5rem;
  background: #d3d6d7;
}
.p-password-panel .p-password-meter .p-password-strength.weak {
  background: #c23019;
}
.p-password-panel .p-password-meter .p-password-strength.medium {
  background: #f9dd4f;
}
.p-password-panel .p-password-meter .p-password-strength.strong {
  background: #35824f;
}

p-password.p-password-clearable .p-password-input {
  padding-right: 2.5rem;
}
p-password.p-password-clearable .p-password-clear-icon {
  color: #0b0c0c;
  right: 0.75rem;
}

p-password.p-password-clearable.p-password-mask .p-password-input {
  padding-right: 4.25rem;
}
p-password.p-password-clearable.p-password-mask .p-password-clear-icon {
  color: #0b0c0c;
  right: 2.5rem;
}

.p-radiobutton {
  width: 24px;
  height: 24px;
}
.p-radiobutton .p-radiobutton-box {
  border: 2px solid #d3d6d7;
  background: #ffffff;
  width: 24px;
  height: 24px;
  color: #0b0c0c;
  border-radius: 50%;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: #ffbf7f;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
  border-color: #ffbf7f;
}
.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 12px;
  height: 12px;
  transition-duration: 0.2s;
  background-color: #fcfcfc;
}
.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #e56000;
  background: #e56000;
}
.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: #cc5600;
  background: #cc5600;
  color: #ef6706;
}

p-radiobutton.ng-dirty.ng-invalid > .p-radiobutton > .p-radiobutton-box {
  border-color: #c23019;
}

.p-input-filled .p-radiobutton .p-radiobutton-box {
  background-color: #f8f9fa;
}
.p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
  background-color: #f3f2f1;
}
.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
  background: #e56000;
}
.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  background: #cc5600;
}

.p-radiobutton-label {
  margin-left: 0.5rem;
}

.p-rating .p-rating-icon {
  color: hotpink;
  margin-left: 0.5rem;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
  font-size: 1.143rem;
}
.p-rating .p-rating-icon.p-rating-cancel {
  color: hotpink;
}
.p-rating .p-rating-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
}
.p-rating .p-rating-icon:first-child {
  margin-left: 0;
}
.p-rating .p-rating-icon.pi-star-fill {
  color: hotpink;
}
.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon:hover {
  color: hotpink;
}
.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon.p-rating-cancel:hover {
  color: hotpink;
}

.p-selectbutton .p-button {
  background: #fff;
  border: 1px solid #d3d6d7;
  color: #0b0c0c;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
}
.p-selectbutton .p-button .p-button-icon-left,
.p-selectbutton .p-button .p-button-icon-right {
  color: #0b0c0c;
}
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
  background: #f8f8f8;
  border-color: #d3d6d7;
  color: #0b0c0c;
}
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
  color: #0b0c0c;
}
.p-selectbutton .p-button.p-highlight {
  background: #1a66a8;
  border-color: #1a66a8;
  color: #fcfcfc;
}
.p-selectbutton .p-button.p-highlight .p-button-icon-left,
.p-selectbutton .p-button.p-highlight .p-button-icon-right {
  color: #fcfcfc;
}
.p-selectbutton .p-button.p-highlight:hover {
  background: #003078;
  border-color: #003078;
  color: #fcfcfc;
}
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
  color: #fcfcfc;
}

p-selectbutton.ng-dirty.ng-invalid > .p-selectbutton > .p-button {
  border-color: #c23019;
}

.p-slider {
  background: #f3f2f1;
  border: 0 none;
  border-radius: 6px;
}
.p-slider.p-slider-horizontal {
  height: 0.25rem;
}
.p-slider.p-slider-horizontal .p-slider-handle {
  margin-top: -0.625rem;
  margin-left: -0.625rem;
}
.p-slider.p-slider-vertical {
  width: 0.25rem;
}
.p-slider.p-slider-vertical .p-slider-handle {
  margin-left: -0.625rem;
  margin-bottom: -0.625rem;
}
.p-slider .p-slider-handle {
  height: 1.25rem;
  width: 1.25rem;
  background: #fff;
  border: 2px solid #e56000;
  border-radius: 50%;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
}
.p-slider .p-slider-handle:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
}
.p-slider .p-slider-range {
  background: #cc5600;
}
.p-slider:not(.p-disabled) .p-slider-handle:hover {
  background: #e56000;
  border-color: #e56000;
}
.p-slider.p-slider-animate.p-slider-horizontal .p-slider-handle {
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s,
    left 0.2s;
}
.p-slider.p-slider-animate.p-slider-horizontal .p-slider-range {
  transition: width 0.2s;
}
.p-slider.p-slider-animate.p-slider-vertical .p-slider-handle {
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s,
    bottom 0.2s;
}
.p-slider.p-slider-animate.p-slider-vertical .p-slider-range {
  transition: height 0.2s;
}

.p-togglebutton.p-button {
  background: #fff;
  border: 1px solid #d3d6d7;
  color: #0b0c0c;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
}
.p-togglebutton.p-button .p-button-icon-left,
.p-togglebutton.p-button .p-button-icon-right {
  color: #0b0c0c;
}
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
  background: #f8f8f8;
  border-color: #d3d6d7;
  color: #0b0c0c;
}
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
  color: #0b0c0c;
}
.p-togglebutton.p-button.p-highlight {
  background: #1a66a8;
  border-color: #1a66a8;
  color: #fcfcfc;
}
.p-togglebutton.p-button.p-highlight .p-button-icon-left,
.p-togglebutton.p-button.p-highlight .p-button-icon-right {
  color: #fcfcfc;
}
.p-togglebutton.p-button.p-highlight:hover {
  background: #003078;
  border-color: #003078;
  color: #fcfcfc;
}
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-left,
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
  color: #fcfcfc;
}

p-togglebutton.ng-dirty.ng-invalid > .p-togglebutton.p-button {
  border-color: #c23019;
}

.p-treeselect {
  background: #ffffff;
  border: 1px solid #d3d6d7;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
  border-radius: 6px;
}
.p-treeselect:not(.p-disabled):hover {
  border-color: #ffbf7f;
}
.p-treeselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
  border-color: #ffbf7f;
}
.p-treeselect .p-treeselect-label {
  padding: 0.75rem 0.75rem;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
}
.p-treeselect .p-treeselect-label.p-placeholder {
  color: #505a5f;
}
.p-treeselect.p-treeselect-chip .p-treeselect-token {
  padding: 0.375rem 0.75rem;
  margin-right: 0.5rem;
  background: #f3f2f1;
  color: #0b0c0c;
  border-radius: 24px;
}
.p-treeselect .p-treeselect-trigger {
  background: transparent;
  color: #0b0c0c;
  width: 3rem;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

p-treeselect.ng-invalid.ng-dirty > .p-treeselect {
  border-color: #c23019;
}

.p-inputwrapper-filled .p-treeselect.p-treeselect-chip .p-treeselect-label {
  padding: 0.375rem 0.75rem;
}

.p-treeselect-panel {
  background: #fff;
  color: #0b0c0c;
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.p-treeselect-panel .p-treeselect-header {
  padding: 0.75rem;
  border-bottom: 1px solid #d3d6d7;
  color: #0b0c0c;
  background: #f8f8f8;
  margin: 0;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container {
  margin-right: 0.5rem;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter {
  padding-right: 1.75rem;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter-icon {
  right: 0.75rem;
  color: #0b0c0c;
}
.p-treeselect-panel
  .p-treeselect-header
  .p-treeselect-filter-container.p-treeselect-clearable-filter
  .p-treeselect-filter {
  padding-right: 3.5rem;
}
.p-treeselect-panel
  .p-treeselect-header
  .p-treeselect-filter-container.p-treeselect-clearable-filter
  .p-treeselect-filter-clear-icon {
  right: 2.5rem;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-close {
  width: 2rem;
  height: 2rem;
  color: #0b0c0c;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-close:enabled:hover {
  color: #0b0c0c;
  border-color: transparent;
  background: #f8f8f8;
}
.p-treeselect-panel .p-treeselect-header .p-treeselect-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
}
.p-treeselect-panel .p-treeselect-items-wrapper .p-tree {
  border: 0 none;
}
.p-treeselect-panel .p-treeselect-items-wrapper .p-treeselect-empty-message {
  padding: 0.75rem 1rem;
  color: #0b0c0c;
  background: transparent;
}

.p-input-filled .p-treeselect {
  background: #f8f9fa;
}
.p-input-filled .p-treeselect:not(.p-disabled):hover {
  background-color: #f3f2f1;
}
.p-input-filled .p-treeselect:not(.p-disabled).p-focus {
  background-color: #f3f2f1;
}

p-treeselect.p-treeselect-clearable .p-treeselect-label-container {
  padding-right: 1.75rem;
}
p-treeselect.p-treeselect-clearable .p-treeselect-clear-icon {
  color: #0b0c0c;
  right: 3rem;
}

.p-button {
  color: #fcfcfc;
  background: #1a66a8;
  border: 1px solid #1a66a8;
  padding: 0.75rem 1.25rem !important;
  font-size: 0.875rem;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
  border-radius: 6px;
}
.p-button:enabled:hover {
  background: #003078;
  color: #fcfcfc;
  border-color: #003078;
}
.p-button:enabled:active {
  background: #002256;
  color: #fcfcfc;
  border-color: #002256;
}
.p-button.p-button-outlined {
  background-color: transparent;
  color: #1a66a8;
  border: 1px solid;
}
.p-button.p-button-outlined:enabled:hover {
  background: rgba(26, 102, 168, 0.04);
  color: #1a66a8;
  border: 1px solid;
}
.p-button.p-button-outlined:enabled:active {
  background: rgba(26, 102, 168, 0.16);
  color: #1a66a8;
  border: 1px solid;
}
.p-button.p-button-outlined.p-button-plain {
  color: #505a5f;
  border-color: #505a5f;
}
.p-button.p-button-outlined.p-button-plain:enabled:hover {
  background: #f8f8f8;
  color: #505a5f;
}
.p-button.p-button-outlined.p-button-plain:enabled:active {
  background: #f8f8f8;
  color: #505a5f;
}
.p-button.p-button-text {
  background-color: transparent;
  color: #1a66a8;
  border-color: transparent;
}
.p-button.p-button-text:enabled:hover {
  background: rgba(26, 102, 168, 0.04);
  color: #1a66a8;
  border-color: transparent;
}
.p-button.p-button-text:enabled:active {
  background: rgba(26, 102, 168, 0.16);
  color: #1a66a8;
  border-color: transparent;
}
.p-button.p-button-text.p-button-plain {
  color: #505a5f;
}
.p-button.p-button-text.p-button-plain:enabled:hover {
  background: #f8f8f8;
  color: #505a5f;
}
.p-button.p-button-text.p-button-plain:enabled:active {
  background: #f8f8f8;
  color: #505a5f;
}
.p-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
}
.p-button .p-button-icon-left {
  margin-right: 0.5rem;
}
.p-button .p-button-icon-right {
  margin-left: 0.5rem;
}
.p-button .p-button-icon-bottom {
  margin-top: 0.5rem;
}
.p-button .p-button-icon-top {
  margin-bottom: 0.5rem;
}
.p-button .p-badge {
  margin-left: 0.5rem;
  min-width: 0.875rem;
  height: 0.875rem;
  line-height: 0.875rem;
  color: #1a66a8;
  background-color: #fcfcfc;
}
.p-button.p-button-raised {
  box-shadow:
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.p-button.p-button-rounded {
  border-radius: 2rem;
}
.p-button.p-button-icon-only {
  width: 3rem;
  padding: 0.75rem;
}
.p-button.p-button-icon-only .p-button-icon-left,
.p-button.p-button-icon-only .p-button-icon-right {
  margin: 0;
}
.p-button.p-button-icon-only.p-button-rounded {
  border-radius: 50%;
  height: 3rem;
}
.p-button.p-button-sm {
  font-size: 0.765625rem;
  padding: 0.65625rem 1.09375rem;
}
.p-button.p-button-sm .p-button-icon {
  font-size: 0.875rem;
}
.p-button.p-button-lg {
  font-size: 1.09375rem;
  padding: 0.9375rem 1.5625rem;
}
.p-button.p-button-lg .p-button-icon {
  font-size: 1.25rem;
}
.p-button.p-button-loading-label-only .p-button-label {
  margin-left: 0.5rem;
}
.p-button.p-button-loading-label-only .p-button-loading-icon {
  margin-right: 0;
}

.p-fluid .p-button {
  width: 100%;
}
.p-fluid .p-button-icon-only {
  width: 3rem;
}
.p-fluid .p-buttonset {
  display: flex;
}
.p-fluid .p-buttonset .p-button {
  flex: 1;
}

.p-button.p-button-secondary,
.p-buttonset.p-button-secondary > .p-button,
.p-splitbutton.p-button-secondary > .p-button {
  color: #0b0c0c;
  background: #fcfcfc;
  border: 1px solid #fcfcfc;
}
.p-button.p-button-secondary:enabled:hover,
.p-buttonset.p-button-secondary > .p-button:enabled:hover,
.p-splitbutton.p-button-secondary > .p-button:enabled:hover {
  background: #f8f8f8;
  color: #0b0c0c;
  border-color: #f8f8f8;
}
.p-button.p-button-secondary:enabled:focus,
.p-buttonset.p-button-secondary > .p-button:enabled:focus,
.p-splitbutton.p-button-secondary > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem white;
}
.p-button.p-button-secondary:enabled:active,
.p-buttonset.p-button-secondary > .p-button:enabled:active,
.p-splitbutton.p-button-secondary > .p-button:enabled:active {
  background: #f3f2f1;
  color: #0b0c0c;
  border-color: #f3f2f1;
}
.p-button.p-button-secondary.p-button-outlined,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined {
  background-color: transparent;
  color: #fcfcfc;
  border: 1px solid;
}
.p-button.p-button-secondary.p-button-outlined:enabled:hover,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:hover {
  background: rgba(252, 252, 252, 0.04);
  color: #fcfcfc;
  border: 1px solid;
}
.p-button.p-button-secondary.p-button-outlined:enabled:active,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:active {
  background: rgba(252, 252, 252, 0.16);
  color: #fcfcfc;
  border: 1px solid;
}
.p-button.p-button-secondary.p-button-text,
.p-buttonset.p-button-secondary > .p-button.p-button-text,
.p-splitbutton.p-button-secondary > .p-button.p-button-text {
  background-color: transparent;
  color: #fcfcfc;
  border-color: transparent;
}
.p-button.p-button-secondary.p-button-text:enabled:hover,
.p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:hover {
  background: rgba(252, 252, 252, 0.04);
  border-color: transparent;
  color: #fcfcfc;
}
.p-button.p-button-secondary.p-button-text:enabled:active,
.p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:active {
  background: rgba(252, 252, 252, 0.16);
  border-color: transparent;
  color: #fcfcfc;
}

.p-button.p-button-info,
.p-buttonset.p-button-info > .p-button,
.p-splitbutton.p-button-info > .p-button {
  color: #fcfcfc;
  background: #e96025;
  border: 1px solid #e96025;
}
.p-button.p-button-info:enabled:hover,
.p-buttonset.p-button-info > .p-button:enabled:hover,
.p-splitbutton.p-button-info > .p-button:enabled:hover {
  background: #cc5600;
  color: #fcfcfc;
  border-color: #cc5600;
}
.p-button.p-button-info:enabled:focus,
.p-buttonset.p-button-info > .p-button:enabled:focus,
.p-splitbutton.p-button-info > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #ffc399;
}
.p-button.p-button-info:enabled:active,
.p-buttonset.p-button-info > .p-button:enabled:active,
.p-splitbutton.p-button-info > .p-button:enabled:active {
  background: #b34b00;
  color: #fcfcfc;
  border-color: #b34b00;
}
.p-button.p-button-info.p-button-outlined,
.p-buttonset.p-button-info > .p-button.p-button-outlined,
.p-splitbutton.p-button-info > .p-button.p-button-outlined {
  background-color: transparent;
  color: #e96025;
  border: 1px solid;
}
.p-button.p-button-info.p-button-outlined:enabled:hover,
.p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:hover {
  background: rgba(233, 96, 37, 0.04);
  color: #e96025;
  border: 1px solid;
}
.p-button.p-button-info.p-button-outlined:enabled:active,
.p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:active {
  background: rgba(233, 96, 37, 0.16);
  color: #e96025;
  border: 1px solid;
}
.p-button.p-button-info.p-button-text,
.p-buttonset.p-button-info > .p-button.p-button-text,
.p-splitbutton.p-button-info > .p-button.p-button-text {
  background-color: transparent;
  color: #e96025;
  border-color: transparent;
}
.p-button.p-button-info.p-button-text:enabled:hover,
.p-buttonset.p-button-info > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-info > .p-button.p-button-text:enabled:hover {
  background: rgba(233, 96, 37, 0.04);
  border-color: transparent;
  color: #e96025;
}
.p-button.p-button-info.p-button-text:enabled:active,
.p-buttonset.p-button-info > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-info > .p-button.p-button-text:enabled:active {
  background: rgba(233, 96, 37, 0.16);
  border-color: transparent;
  color: #e96025;
}

.p-button.p-button-success,
.p-buttonset.p-button-success > .p-button,
.p-splitbutton.p-button-success > .p-button {
  color: #fcfcfc;
  background: #35824f;
  border: 1px solid #35824f;
}
.p-button.p-button-success:enabled:hover,
.p-buttonset.p-button-success > .p-button:enabled:hover,
.p-splitbutton.p-button-success > .p-button:enabled:hover {
  background: #255a37;
  color: #fcfcfc;
  border-color: #255a37;
}
.p-button.p-button-success:enabled:focus,
.p-buttonset.p-button-success > .p-button:enabled:focus,
.p-splitbutton.p-button-success > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #96d4ab;
}
.p-button.p-button-success:enabled:active,
.p-buttonset.p-button-success > .p-button:enabled:active,
.p-splitbutton.p-button-success > .p-button:enabled:active {
  background: #132d1b;
  color: #fcfcfc;
  border-color: #132d1b;
}
.p-button.p-button-success.p-button-outlined,
.p-buttonset.p-button-success > .p-button.p-button-outlined,
.p-splitbutton.p-button-success > .p-button.p-button-outlined {
  background-color: transparent;
  color: #35824f;
  border: 1px solid;
}
.p-button.p-button-success.p-button-outlined:enabled:hover,
.p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:hover {
  background: rgba(53, 130, 79, 0.04);
  color: #35824f;
  border: 1px solid;
}
.p-button.p-button-success.p-button-outlined:enabled:active,
.p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:active {
  background: rgba(53, 130, 79, 0.16);
  color: #35824f;
  border: 1px solid;
}
.p-button.p-button-success.p-button-text,
.p-buttonset.p-button-success > .p-button.p-button-text,
.p-splitbutton.p-button-success > .p-button.p-button-text {
  background-color: transparent;
  color: #35824f;
  border-color: transparent;
}
.p-button.p-button-success.p-button-text:enabled:hover,
.p-buttonset.p-button-success > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-success > .p-button.p-button-text:enabled:hover {
  background: rgba(53, 130, 79, 0.04);
  border-color: transparent;
  color: #35824f;
}
.p-button.p-button-success.p-button-text:enabled:active,
.p-buttonset.p-button-success > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-success > .p-button.p-button-text:enabled:active {
  background: rgba(53, 130, 79, 0.16);
  border-color: transparent;
  color: #35824f;
}

.p-button.p-button-warning,
.p-buttonset.p-button-warning > .p-button,
.p-splitbutton.p-button-warning > .p-button {
  color: #fcfcfc;
  background: #f9dd4f;
  border: 1px solid hotpink;
}
.p-button.p-button-warning:enabled:hover,
.p-buttonset.p-button-warning > .p-button:enabled:hover,
.p-splitbutton.p-button-warning > .p-button:enabled:hover {
  background: hotpink;
  color: hotpink;
  border-color: hotpink;
}
.p-button.p-button-warning:enabled:focus,
.p-buttonset.p-button-warning > .p-button:enabled:focus,
.p-splitbutton.p-button-warning > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem white;
}
.p-button.p-button-warning:enabled:active,
.p-buttonset.p-button-warning > .p-button:enabled:active,
.p-splitbutton.p-button-warning > .p-button:enabled:active {
  background: hotpink;
  color: hotpink;
  border-color: hotpink;
}
.p-button.p-button-warning.p-button-outlined,
.p-buttonset.p-button-warning > .p-button.p-button-outlined,
.p-splitbutton.p-button-warning > .p-button.p-button-outlined {
  background-color: transparent;
  color: #f9dd4f;
  border: 1px solid;
}
.p-button.p-button-warning.p-button-outlined:enabled:hover,
.p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:hover {
  background: rgba(249, 221, 79, 0.04);
  color: #f9dd4f;
  border: 1px solid;
}
.p-button.p-button-warning.p-button-outlined:enabled:active,
.p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:active {
  background: rgba(249, 221, 79, 0.16);
  color: #f9dd4f;
  border: 1px solid;
}
.p-button.p-button-warning.p-button-text,
.p-buttonset.p-button-warning > .p-button.p-button-text,
.p-splitbutton.p-button-warning > .p-button.p-button-text {
  background-color: transparent;
  color: #f9dd4f;
  border-color: transparent;
}
.p-button.p-button-warning.p-button-text:enabled:hover,
.p-buttonset.p-button-warning > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:hover {
  background: rgba(249, 221, 79, 0.04);
  border-color: transparent;
  color: #f9dd4f;
}
.p-button.p-button-warning.p-button-text:enabled:active,
.p-buttonset.p-button-warning > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:active {
  background: rgba(249, 221, 79, 0.16);
  border-color: transparent;
  color: #f9dd4f;
}

.p-button.p-button-help,
.p-buttonset.p-button-help > .p-button,
.p-splitbutton.p-button-help > .p-button {
  color: #fcfcfc;
  background: #e56000;
  border: 1px solid hotpink;
}
.p-button.p-button-help:enabled:hover,
.p-buttonset.p-button-help > .p-button:enabled:hover,
.p-splitbutton.p-button-help > .p-button:enabled:hover {
  background: hotpink;
  color: #0b0c0c;
  border-color: hotpink;
}
.p-button.p-button-help:enabled:focus,
.p-buttonset.p-button-help > .p-button:enabled:focus,
.p-splitbutton.p-button-help > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem white;
}
.p-button.p-button-help:enabled:active,
.p-buttonset.p-button-help > .p-button:enabled:active,
.p-splitbutton.p-button-help > .p-button:enabled:active {
  background: hotpink;
  color: #0b0c0c;
  border-color: hotpink;
}
.p-button.p-button-help.p-button-outlined,
.p-buttonset.p-button-help > .p-button.p-button-outlined,
.p-splitbutton.p-button-help > .p-button.p-button-outlined {
  background-color: transparent;
  color: #e56000;
  border: 1px solid;
}
.p-button.p-button-help.p-button-outlined:enabled:hover,
.p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:hover {
  background: rgba(229, 96, 0, 0.04);
  color: #e56000;
  border: 1px solid;
}
.p-button.p-button-help.p-button-outlined:enabled:active,
.p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:active {
  background: rgba(229, 96, 0, 0.16);
  color: #e56000;
  border: 1px solid;
}
.p-button.p-button-help.p-button-text,
.p-buttonset.p-button-help > .p-button.p-button-text,
.p-splitbutton.p-button-help > .p-button.p-button-text {
  background-color: transparent;
  color: #e56000;
  border-color: transparent;
}
.p-button.p-button-help.p-button-text:enabled:hover,
.p-buttonset.p-button-help > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-help > .p-button.p-button-text:enabled:hover {
  background: rgba(229, 96, 0, 0.04);
  border-color: transparent;
  color: #e56000;
}
.p-button.p-button-help.p-button-text:enabled:active,
.p-buttonset.p-button-help > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-help > .p-button.p-button-text:enabled:active {
  background: rgba(229, 96, 0, 0.16);
  border-color: transparent;
  color: #e56000;
}

.p-button.p-button-danger,
.p-buttonset.p-button-danger > .p-button,
.p-splitbutton.p-button-danger > .p-button {
  color: #fcfcfc;
  background: #c23019;
  border: 1px solid #c23019;
}
.p-button.p-button-danger:enabled:hover,
.p-buttonset.p-button-danger > .p-button:enabled:hover,
.p-splitbutton.p-button-danger > .p-button:enabled:hover {
  background: #9f2815;
  color: #fcfcfc;
  border-color: #9f2815;
}
.p-button.p-button-danger:enabled:focus,
.p-buttonset.p-button-danger > .p-button:enabled:focus,
.p-splitbutton.p-button-danger > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #f2a79b;
}
.p-button.p-button-danger:enabled:active,
.p-buttonset.p-button-danger > .p-button:enabled:active,
.p-splitbutton.p-button-danger > .p-button:enabled:active {
  background: #6a1b0e;
  color: #fcfcfc;
  border-color: #6a1b0e;
}
.p-button.p-button-danger.p-button-outlined,
.p-buttonset.p-button-danger > .p-button.p-button-outlined,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined {
  background-color: transparent;
  color: #c23019;
  border: 1px solid;
}
.p-button.p-button-danger.p-button-outlined:enabled:hover,
.p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:hover {
  background: rgba(194, 48, 25, 0.04);
  color: #c23019;
  border: 1px solid;
}
.p-button.p-button-danger.p-button-outlined:enabled:active,
.p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:active {
  background: rgba(194, 48, 25, 0.16);
  color: #c23019;
  border: 1px solid;
}
.p-button.p-button-danger.p-button-text,
.p-buttonset.p-button-danger > .p-button.p-button-text,
.p-splitbutton.p-button-danger > .p-button.p-button-text {
  background-color: transparent;
  color: #c23019;
  border-color: transparent;
}
.p-button.p-button-danger.p-button-text:enabled:hover,
.p-buttonset.p-button-danger > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:hover {
  background: rgba(194, 48, 25, 0.04);
  border-color: transparent;
  color: #c23019;
}
.p-button.p-button-danger.p-button-text:enabled:active,
.p-buttonset.p-button-danger > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:active {
  background: rgba(194, 48, 25, 0.16);
  border-color: transparent;
  color: #c23019;
}

.p-button.p-button-link {
  color: #1a66a8;
  background: transparent;
  border: transparent;
}
.p-button.p-button-link:enabled:hover {
  background: transparent;
  color: #003078;
  border-color: transparent;
}
.p-button.p-button-link:enabled:hover .p-button-label {
  text-decoration: underline;
}
.p-button.p-button-link:enabled:focus {
  background: transparent;
  box-shadow: 0 0 0 0.2rem #88beed;
  border-color: transparent;
}
.p-button.p-button-link:enabled:active {
  background: transparent;
  color: #1a66a8;
  border-color: transparent;
}

.p-speeddial-button.p-button.p-button-icon-only {
  width: 4rem;
  height: 4rem;
}
.p-speeddial-button.p-button.p-button-icon-only .p-button-icon {
  font-size: 1.3rem;
}

.p-speeddial-action {
  width: 3rem;
  height: 3rem;
  background: #2e3336;
  color: #fff;
}
.p-speeddial-action:hover {
  background: #022354;
  color: #fff;
}

.p-speeddial-direction-up .p-speeddial-item {
  margin: 0.25rem 0;
}
.p-speeddial-direction-up .p-speeddial-item:first-child {
  margin-bottom: 0.5rem;
}

.p-speeddial-direction-down .p-speeddial-item {
  margin: 0.25rem 0;
}
.p-speeddial-direction-down .p-speeddial-item:first-child {
  margin-top: 0.5rem;
}

.p-speeddial-direction-left .p-speeddial-item {
  margin: 0 0.25rem;
}
.p-speeddial-direction-left .p-speeddial-item:first-child {
  margin-right: 0.5rem;
}

.p-speeddial-direction-right .p-speeddial-item {
  margin: 0 0.25rem;
}
.p-speeddial-direction-right .p-speeddial-item:first-child {
  margin-left: 0.5rem;
}

.p-speeddial-circle .p-speeddial-item,
.p-speeddial-semi-circle .p-speeddial-item,
.p-speeddial-quarter-circle .p-speeddial-item {
  margin: 0;
}
.p-speeddial-circle .p-speeddial-item:first-child,
.p-speeddial-circle .p-speeddial-item:last-child,
.p-speeddial-semi-circle .p-speeddial-item:first-child,
.p-speeddial-semi-circle .p-speeddial-item:last-child,
.p-speeddial-quarter-circle .p-speeddial-item:first-child,
.p-speeddial-quarter-circle .p-speeddial-item:last-child {
  margin: 0;
}

.p-speeddial-mask {
  background-color: rgba(46, 51, 54, 0.6);
}

.p-splitbutton {
  border-radius: 6px;
}
.p-splitbutton.p-button-outlined > .p-button {
  background-color: transparent;
  color: #1a66a8;
  border: 1px solid;
}
.p-splitbutton.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(26, 102, 168, 0.04);
  color: #1a66a8;
}
.p-splitbutton.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(26, 102, 168, 0.16);
  color: #1a66a8;
}
.p-splitbutton.p-button-outlined.p-button-plain > .p-button {
  color: #505a5f;
  border-color: #505a5f;
}
.p-splitbutton.p-button-outlined.p-button-plain > .p-button:enabled:hover,
.p-splitbutton.p-button-outlined.p-button-plain > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: #f8f8f8;
  color: #505a5f;
}
.p-splitbutton.p-button-outlined.p-button-plain > .p-button:enabled:active,
.p-splitbutton.p-button-outlined.p-button-plain > .p-button:not(button):not(a):not(.p-disabled):active {
  background: #f8f8f8;
  color: #505a5f;
}
.p-splitbutton.p-button-text > .p-button {
  background-color: transparent;
  color: #1a66a8;
  border-color: transparent;
}
.p-splitbutton.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(26, 102, 168, 0.04);
  color: #1a66a8;
  border-color: transparent;
}
.p-splitbutton.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(26, 102, 168, 0.16);
  color: #1a66a8;
  border-color: transparent;
}
.p-splitbutton.p-button-text.p-button-plain > .p-button {
  color: #505a5f;
}
.p-splitbutton.p-button-text.p-button-plain > .p-button:enabled:hover,
.p-splitbutton.p-button-text.p-button-plain > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: #f8f8f8;
  color: #505a5f;
}
.p-splitbutton.p-button-text.p-button-plain > .p-button:enabled:active,
.p-splitbutton.p-button-text.p-button-plain > .p-button:not(button):not(a):not(.p-disabled):active {
  background: #f8f8f8;
  color: #505a5f;
}
.p-splitbutton.p-button-raised {
  box-shadow:
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.p-splitbutton.p-button-rounded {
  border-radius: 2rem;
}
.p-splitbutton.p-button-rounded > .p-button {
  border-radius: 2rem;
}
.p-splitbutton.p-button-sm > .p-button {
  font-size: 0.765625rem;
  padding: 0.65625rem 1.09375rem;
}
.p-splitbutton.p-button-sm > .p-button .p-button-icon {
  font-size: 0.875rem;
}
.p-splitbutton.p-button-lg > .p-button {
  font-size: 1.09375rem;
  padding: 0.9375rem 1.5625rem;
}
.p-splitbutton.p-button-lg > .p-button .p-button-icon {
  font-size: 1.25rem;
}

.p-splitbutton.p-button-secondary.p-button-outlined > .p-button {
  background-color: transparent;
  color: #fcfcfc;
  border: 1px solid;
}
.p-splitbutton.p-button-secondary.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-secondary.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(252, 252, 252, 0.04);
  color: #fcfcfc;
}
.p-splitbutton.p-button-secondary.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-secondary.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(252, 252, 252, 0.16);
  color: #fcfcfc;
}
.p-splitbutton.p-button-secondary.p-button-text > .p-button {
  background-color: transparent;
  color: #fcfcfc;
  border-color: transparent;
}
.p-splitbutton.p-button-secondary.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-secondary.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(252, 252, 252, 0.04);
  border-color: transparent;
  color: #fcfcfc;
}
.p-splitbutton.p-button-secondary.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-secondary.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(252, 252, 252, 0.16);
  border-color: transparent;
  color: #fcfcfc;
}

.p-splitbutton.p-button-info.p-button-outlined > .p-button {
  background-color: transparent;
  color: #e96025;
  border: 1px solid;
}
.p-splitbutton.p-button-info.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-info.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(233, 96, 37, 0.04);
  color: #e96025;
}
.p-splitbutton.p-button-info.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-info.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(233, 96, 37, 0.16);
  color: #e96025;
}
.p-splitbutton.p-button-info.p-button-text > .p-button {
  background-color: transparent;
  color: #e96025;
  border-color: transparent;
}
.p-splitbutton.p-button-info.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-info.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(233, 96, 37, 0.04);
  border-color: transparent;
  color: #e96025;
}
.p-splitbutton.p-button-info.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-info.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(233, 96, 37, 0.16);
  border-color: transparent;
  color: #e96025;
}

.p-splitbutton.p-button-success.p-button-outlined > .p-button {
  background-color: transparent;
  color: #35824f;
  border: 1px solid;
}
.p-splitbutton.p-button-success.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-success.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(53, 130, 79, 0.04);
  color: #35824f;
}
.p-splitbutton.p-button-success.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-success.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(53, 130, 79, 0.16);
  color: #35824f;
}
.p-splitbutton.p-button-success.p-button-text > .p-button {
  background-color: transparent;
  color: #35824f;
  border-color: transparent;
}
.p-splitbutton.p-button-success.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-success.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(53, 130, 79, 0.04);
  border-color: transparent;
  color: #35824f;
}
.p-splitbutton.p-button-success.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-success.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(53, 130, 79, 0.16);
  border-color: transparent;
  color: #35824f;
}

.p-splitbutton.p-button-warning.p-button-outlined > .p-button {
  background-color: transparent;
  color: #f9dd4f;
  border: 1px solid;
}
.p-splitbutton.p-button-warning.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-warning.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(249, 221, 79, 0.04);
  color: #f9dd4f;
}
.p-splitbutton.p-button-warning.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-warning.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(249, 221, 79, 0.16);
  color: #f9dd4f;
}
.p-splitbutton.p-button-warning.p-button-text > .p-button {
  background-color: transparent;
  color: #f9dd4f;
  border-color: transparent;
}
.p-splitbutton.p-button-warning.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-warning.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(249, 221, 79, 0.04);
  border-color: transparent;
  color: #f9dd4f;
}
.p-splitbutton.p-button-warning.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-warning.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(249, 221, 79, 0.16);
  border-color: transparent;
  color: #f9dd4f;
}

.p-splitbutton.p-button-help.p-button-outlined > .p-button {
  background-color: transparent;
  color: #e56000;
  border: 1px solid;
}
.p-splitbutton.p-button-help.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-help.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(229, 96, 0, 0.04);
  color: #e56000;
}
.p-splitbutton.p-button-help.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-help.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(229, 96, 0, 0.16);
  color: #e56000;
}
.p-splitbutton.p-button-help.p-button-text > .p-button {
  background-color: transparent;
  color: #e56000;
  border-color: transparent;
}
.p-splitbutton.p-button-help.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-help.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(229, 96, 0, 0.04);
  border-color: transparent;
  color: #e56000;
}
.p-splitbutton.p-button-help.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-help.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(229, 96, 0, 0.16);
  border-color: transparent;
  color: #e56000;
}

.p-splitbutton.p-button-danger.p-button-outlined > .p-button {
  background-color: transparent;
  color: #c23019;
  border: 1px solid;
}
.p-splitbutton.p-button-danger.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-danger.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(194, 48, 25, 0.04);
  color: #c23019;
}
.p-splitbutton.p-button-danger.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-danger.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(194, 48, 25, 0.16);
  color: #c23019;
}
.p-splitbutton.p-button-danger.p-button-text > .p-button {
  background-color: transparent;
  color: #c23019;
  border-color: transparent;
}
.p-splitbutton.p-button-danger.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-danger.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgba(194, 48, 25, 0.04);
  border-color: transparent;
  color: #c23019;
}
.p-splitbutton.p-button-danger.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-danger.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
  background: rgba(194, 48, 25, 0.16);
  border-color: transparent;
  color: #c23019;
}

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  width: 2rem;
  height: 2rem;
  color: #0b0c0c;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
  margin: 0.5rem;
}
.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
.p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
  color: #0b0c0c;
  border-color: transparent;
  background: #f8f8f8;
}
.p-carousel .p-carousel-content .p-carousel-prev:focus,
.p-carousel .p-carousel-content .p-carousel-next:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
}
.p-carousel .p-carousel-indicators {
  padding: 0.75rem;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  background-color: #d3d6d7;
  width: 2rem;
  height: 0.5rem;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
  border-radius: 0;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
  background: #e56000;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background: #ffe5cc;
  color: #0b0c0c;
}

.p-datatable .p-paginator-top {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-datatable .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-datatable .p-datatable-header {
  background: #f3f2f1;
  color: #0b0c0c;
  border: 1px solid #7c8387;
  border-width: 0px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-datatable .p-datatable-footer {
  background: #fff;
  color: #0b0c0c;
  border: 1px solid #d3d6d7;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #a7acaf;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #0b0c0c;
  background: #fff;
  transition: box-shadow 0.2s;
}
.p-datatable .p-datatable-tfoot > tr > td {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #d3d6d7;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #0b0c0c;
  background: #fff;
}
.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: #0b0c0c;
  margin-left: 0.5rem;
}
.p-datatable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 0.875rem;
  min-width: 0.875rem;
  line-height: 0.875rem;
  color: #0b0c0c;
  background: #ffe5cc;
  margin-left: 0.5rem;
}
.p-datatable .p-sortable-column:not(.p-highlight):hover {
  background: #fff3e6;
  color: #0b0c0c;
}
.p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: #0b0c0c;
}
.p-datatable .p-sortable-column.p-highlight {
  background: transparent;
  color: #ef6706;
}
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #ef6706;
}
.p-datatable .p-sortable-column.p-highlight:hover {
  background: #f3f2f1;
  color: #ef6706;
}
.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
  color: #ef6706;
}
.p-datatable .p-sortable-column:focus {
  box-shadow: inset 0 0 0 0.15rem #ffbf7f;
  outline: 0 none;
}
.p-datatable .p-datatable-tbody > tr {
  background: #fff;
  color: #0b0c0c;
  transition: box-shadow 0.2s;
}
.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid #d3d6d7;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
  width: 2rem;
  height: 2rem;
  color: #0b0c0c;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
  color: #0b0c0c;
  border-color: transparent;
  background: #f8f8f8;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
  margin-right: 0.5rem;
}
.p-datatable .p-datatable-tbody > tr:focus {
  outline: 0.15rem solid #ffbf7f;
  outline-offset: 0.15rem;
}
.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #ffe5cc;
  color: #0b0c0c;
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
  box-shadow: inset 0 2px 0 0 #ffe5cc;
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
  box-shadow: inset 0 -2px 0 0 #ffe5cc;
}
.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
  background: #fff3e6;
  color: #0b0c0c;
}
.p-datatable .p-column-resizer-helper {
  background: #e56000;
}
.p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer {
  background: #f3f2f1;
}
.p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-thead,
.p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-tfoot {
  background-color: #fff;
}
.p-datatable .p-datatable-loading-icon {
  font-size: 2rem;
}
.p-datatable.p-datatable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-footer {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-top {
  border-width: 0 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-bottom {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
  border-width: 1px 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
  border-width: 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
  border-width: 1px;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
  background: #fcfcfc;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight {
  background: #ffe5cc;
  color: #0b0c0c;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler {
  color: #0b0c0c;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler:hover {
  color: #0b0c0c;
}
.p-datatable.p-datatable-sm .p-datatable-header {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-footer {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-lg .p-datatable-header {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-footer {
  padding: 1.25rem 1.25rem;
}

.p-dataview .p-paginator-top {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-dataview .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-dataview .p-dataview-header {
  background: #f3f2f1;
  color: #0b0c0c;
  border: 1px solid #7c8387;
  border-width: 0px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-dataview .p-dataview-content {
  background: #fff;
  color: #0b0c0c;
  border: 0 none;
  padding: 0;
}
.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
  border: solid #d3d6d7;
  border-width: 0 0 1px 0;
}
.p-dataview .p-dataview-footer {
  background: #fff;
  color: #0b0c0c;
  border: 1px solid #d3d6d7;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.p-dataview .p-dataview-loading-icon {
  font-size: 2rem;
}
.p-dataview .p-dataview-emptymessage {
  padding: 0.75rem 1rem;
}

.p-column-filter-row .p-column-filter-menu-button,
.p-column-filter-row .p-column-filter-clear-button {
  margin-left: 0.5rem;
}

.p-column-filter-menu-button {
  width: 2rem;
  height: 2rem;
  color: #0b0c0c;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
}
.p-column-filter-menu-button:hover {
  color: #0b0c0c;
  border-color: transparent;
  background: #f8f8f8;
}
.p-column-filter-menu-button.p-column-filter-menu-button-open,
.p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
  background: #f8f8f8;
  color: #0b0c0c;
}
.p-column-filter-menu-button.p-column-filter-menu-button-active,
.p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
  background: #ffe5cc;
  color: #0b0c0c;
}
.p-column-filter-menu-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
}

.p-column-filter-clear-button {
  width: 2rem;
  height: 2rem;
  color: #0b0c0c;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
}
.p-column-filter-clear-button:hover {
  color: #0b0c0c;
  border-color: transparent;
  background: #f8f8f8;
}
.p-column-filter-clear-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
}

.p-column-filter-overlay {
  background: #fff;
  color: #0b0c0c;
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  min-width: 12.5rem;
}
.p-column-filter-overlay .p-column-filter-row-items {
  padding: 0.75rem 0 0 0;
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item {
  margin: 0;
  padding: 0.75rem 1rem;
  border: 0 none;
  color: #0b0c0c;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 0;
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item.p-highlight {
  color: #0b0c0c;
  background: #ffe5cc;
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:not(.p-highlight):not(.p-disabled):hover {
  color: #0b0c0c;
  background: #f3f2f1;
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #ffbf7f;
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}

.p-column-filter-overlay-menu .p-column-filter-operator {
  padding: 0.75rem;
  border-bottom: 1px solid #d3d6d7;
  color: #0b0c0c;
  background: #f8f8f8;
  margin: 0;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-column-filter-overlay-menu .p-column-filter-constraint {
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #dee2e6;
}
.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-matchmode-dropdown {
  margin-bottom: 0.5rem;
}
.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-remove-button {
  margin-top: 0.5rem;
}
.p-column-filter-overlay-menu .p-column-filter-constraint:last-child {
  border-bottom: 0 none;
}
.p-column-filter-overlay-menu .p-column-filter-add-rule {
  padding: 0.75rem 1.25rem;
}
.p-column-filter-overlay-menu .p-column-filter-buttonbar {
  padding: 0.75rem 1rem;
}

.fc {
  /* FullCalendar 4 */
  /* FullCalendar 5 */
}
.fc.fc-unthemed .fc-view-container th {
  background: #f3f2f1;
  border: 1px solid #d3d6d7;
  color: #0b0c0c;
}
.fc.fc-unthemed .fc-view-container td.fc-widget-content {
  background: #f8f8f8;
  border: 1px solid #fff;
  color: #0b0c0c;
}
.fc.fc-unthemed .fc-view-container td.fc-head-container {
  border: 1px solid #d3d6d7;
}
.fc.fc-unthemed .fc-view-container .fc-row {
  border-right: 1px solid #d3d6d7;
}
.fc.fc-unthemed .fc-view-container .fc-event {
  background: #f8f8f8;
  border: 1px solid #d3d6d7;
  color: #ef6706;
}
.fc.fc-unthemed .fc-view-container .fc-divider {
  background: #f3f2f1;
  border: 1px solid #d3d6d7;
}
.fc.fc-unthemed .fc-toolbar .fc-button {
  color: #fcfcfc;
  background: #1a66a8;
  border: 1px solid #1a66a8;
  font-size: 0.875rem;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
  border-radius: 6px;
  display: flex;
  align-items: center;
}
.fc.fc-unthemed .fc-toolbar .fc-button:enabled:hover {
  background: #003078;
  color: #fcfcfc;
  border-color: #003078;
}
.fc.fc-unthemed .fc-toolbar .fc-button:enabled:active {
  background: #002256;
  color: #fcfcfc;
  border-color: #002256;
}
.fc.fc-unthemed .fc-toolbar .fc-button:enabled:active:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
}
.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-left {
  font-family: 'PrimeIcons' !important;
  text-indent: 0;
  font-size: 1rem;
}
.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-left:before {
  content: '\e900';
}
.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-right {
  font-family: 'PrimeIcons' !important;
  text-indent: 0;
  font-size: 1rem;
}
.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-right:before {
  content: '\e901';
}
.fc.fc-unthemed .fc-toolbar .fc-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button {
  background: #fff;
  border: 1px solid #d3d6d7;
  color: #0b0c0c;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button:hover,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button:hover,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
  background: #f8f8f8;
  border-color: #d3d6d7;
  color: #0b0c0c;
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
  background: #1a66a8;
  border-color: #1a66a8;
  color: #fcfcfc;
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover {
  background: #003078;
  border-color: #003078;
  color: #fcfcfc;
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button:focus,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button:focus,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
  z-index: 1;
}
.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button {
  border-radius: 0;
}
.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.fc.fc-theme-standard .fc-view-harness .fc-scrollgrid {
  border-color: #fff;
}
.fc.fc-theme-standard .fc-view-harness th {
  background: #f3f2f1;
  border-color: #d3d6d7;
  color: #0b0c0c;
}
.fc.fc-theme-standard .fc-view-harness td {
  color: #0b0c0c;
  border-color: #fff;
}
.fc.fc-theme-standard .fc-view-harness .fc-view {
  background: #f8f8f8;
}
.fc.fc-theme-standard .fc-view-harness .fc-popover {
  background: none;
  border: 0 none;
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header {
  border: 1px solid #d3d6d7;
  padding: 0.75rem 1rem;
  background: #f3f2f1;
  color: #0b0c0c;
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close {
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-family: 'PrimeIcons' !important;
  font-size: 1rem;
  width: 2rem;
  height: 2rem;
  color: #0b0c0c;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:before {
  content: '\e90b';
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:enabled:hover {
  color: #0b0c0c;
  border-color: transparent;
  background: #f8f8f8;
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-body {
  padding: 0.75rem 1rem;
  border: 1px solid #fff;
  background: #f8f8f8;
  color: #0b0c0c;
  border-top: 0 none;
}
.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event {
  color: #ef6706;
  background: #f8f8f8;
  border-color: #d3d6d7;
}
.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event .fc-event-main {
  color: #ef6706;
}
.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-dot-event .fc-daygrid-event-dot {
  background: #f8f8f8;
  border-color: #d3d6d7;
}
.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-dot-event:hover {
  background: #f3f2f1;
  color: #0b0c0c;
}
.fc.fc-theme-standard .fc-view-harness .fc-cell-shaded {
  background: #f3f2f1;
}
.fc.fc-theme-standard .fc-toolbar .fc-button {
  color: #fcfcfc;
  background: #1a66a8;
  border: 1px solid #1a66a8;
  font-size: 0.875rem;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
  border-radius: 6px;
}
.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:hover {
  background: #003078;
  color: #fcfcfc;
  border-color: #003078;
}
.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:active {
  background: #002256;
  color: #fcfcfc;
  border-color: #002256;
}
.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:active:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
}
.fc.fc-theme-standard .fc-toolbar .fc-button:disabled {
  opacity: 0.5;
  color: #fcfcfc;
  background: #1a66a8;
  border: 1px solid #1a66a8;
}
.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-left {
  font-family: 'PrimeIcons' !important;
  text-indent: 0;
  font-size: 1rem;
}
.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-left:before {
  content: '\e900';
}
.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-right {
  font-family: 'PrimeIcons' !important;
  text-indent: 0;
  font-size: 1rem;
}
.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-right:before {
  content: '\e901';
}
.fc.fc-theme-standard .fc-toolbar .fc-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
}
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button {
  background: #fff;
  border: 1px solid #d3d6d7;
  color: #0b0c0c;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
}
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button:hover,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button:hover,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
  background: #f8f8f8;
  border-color: #d3d6d7;
  color: #0b0c0c;
}
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
  background: #1a66a8;
  border-color: #1a66a8;
  color: #fcfcfc;
}
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover {
  background: #003078;
  border-color: #003078;
  color: #fcfcfc;
}
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button:not(:disabled):focus,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button:not(:disabled):focus,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button:not(:disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
  z-index: 1;
}
.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button {
  border-radius: 0;
}
.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.fc.fc-theme-standard a {
  color: #0b0c0c;
}
.fc.fc-theme-standard .fc-highlight {
  color: #0b0c0c;
  background: #ffe5cc;
}

.p-orderlist .p-orderlist-controls {
  padding: 0.75rem 1rem;
}
.p-orderlist .p-orderlist-controls .p-button {
  margin-bottom: 0.5rem;
}
.p-orderlist .p-orderlist-header {
  background: #f3f2f1;
  color: #0b0c0c;
  border: 1px solid #d3d6d7;
  padding: 0.75rem 1rem;
  border-bottom: 0 none;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-orderlist .p-orderlist-header .p-orderlist-title {
  font-weight: 600;
}
.p-orderlist .p-orderlist-filter-container {
  padding: 0.75rem 1rem;
  background: #f8f8f8;
  border: 1px solid #d3d6d7;
  border-bottom: 0 none;
}
.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-input {
  padding-right: 1.75rem;
}
.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-icon {
  right: 0.75rem;
  color: #0b0c0c;
}
.p-orderlist .p-orderlist-list {
  border: 1px solid #fff;
  background: #f8f8f8;
  color: #0b0c0c;
  padding: 0.75rem 0 0 0;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-orderlist .p-orderlist-list .p-orderlist-item {
  padding: 0.75rem 1rem;
  margin: 0;
  border: 0 none;
  color: #0b0c0c;
  background: transparent;
  transition: box-shadow 0.2s;
}
.p-orderlist .p-orderlist-list .p-orderlist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #ffbf7f;
}
.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
  color: #0b0c0c;
  background: #ffe5cc;
}
.p-orderlist .p-orderlist-list .p-orderlist-empty-message {
  padding: 0.75rem 1rem;
  color: #0b0c0c;
}
.p-orderlist .p-orderlist-list:not(.cdk-drop-list-dragging) .p-orderlist-item:not(.p-highlight):hover {
  background: #f3f2f1;
  color: #0b0c0c;
}
.p-orderlist.p-orderlist-striped .p-orderlist-list .p-orderlist-item:nth-child(even) {
  background: #f6f6f6;
}
.p-orderlist.p-orderlist-striped .p-orderlist-list .p-orderlist-item:nth-child(even):hover {
  background: #f3f2f1;
}

.p-orderlist-item.cdk-drag-preview {
  padding: 0.75rem 1rem;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: 0 none;
  color: #0b0c0c;
  background: #f8f8f8;
  margin: 0;
}

.p-organizationchart .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover {
  background: #f3f2f1;
  color: #0b0c0c;
}
.p-organizationchart .p-organizationchart-node-content.p-highlight {
  background: #ffe5cc;
  color: #0b0c0c;
}
.p-organizationchart .p-organizationchart-node-content.p-highlight .p-node-toggler i {
  color: #ffa44d;
}
.p-organizationchart .p-organizationchart-line-down {
  background: #505a5f;
}
.p-organizationchart .p-organizationchart-line-left {
  border-right: 1px solid #fff;
  border-color: #505a5f;
}
.p-organizationchart .p-organizationchart-line-top {
  border-top: 1px solid #fff;
  border-color: #505a5f;
}
.p-organizationchart .p-organizationchart-node-content {
  border: 1px solid #fff;
  background: #f8f8f8;
  color: #0b0c0c;
  padding: 0.75rem 1rem;
}
.p-organizationchart .p-organizationchart-node-content .p-node-toggler {
  background: inherit;
  color: inherit;
  border-radius: 50%;
}
.p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
}

.p-paginator {
  background: transparent;
  color: #0b0c0c;
  border: transparent;
  border-width: 0;
  padding: 0.5rem 1rem;
  border-radius: 6px;
}
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  background-color: transparent;
  border: 0 none;
  color: #0b0c0c;
  min-width: 3rem;
  height: 3rem;
  margin: 0.156rem;
  transition: box-shadow 0.2s;
  border-radius: 50%;
}
.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
  background: #ffe5cc;
  border-color: transparent;
  color: #0b0c0c;
}
.p-paginator .p-paginator-first {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}
.p-paginator .p-paginator-last {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}
.p-paginator .p-dropdown {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  height: 3rem;
}
.p-paginator .p-dropdown .p-dropdown-label {
  padding-right: 0;
}
.p-paginator .p-paginator-page-input {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.p-paginator .p-paginator-page-input .p-inputtext {
  max-width: 3rem;
}
.p-paginator .p-paginator-current {
  background-color: transparent;
  border: 0 none;
  color: #0b0c0c;
  min-width: 3rem;
  height: 3rem;
  margin: 0.156rem;
  padding: 0 0.5rem;
}
.p-paginator .p-paginator-pages .p-paginator-page {
  background-color: transparent;
  border: 0 none;
  color: #0b0c0c;
  min-width: 3rem;
  height: 3rem;
  margin: 0.156rem;
  transition: box-shadow 0.2s;
  border-radius: 50%;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #ffe5cc;
  border-color: #ffe5cc;
  color: #0b0c0c;
}
.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  background: #ffe5cc;
  border-color: transparent;
  color: #0b0c0c;
}

.p-picklist .p-picklist-buttons {
  padding: 0.75rem 1rem;
}
.p-picklist .p-picklist-buttons .p-button {
  margin-bottom: 0.5rem;
}
.p-picklist .p-picklist-header {
  background: #f3f2f1;
  color: #0b0c0c;
  border: 1px solid #d3d6d7;
  padding: 0.75rem 1rem;
  border-bottom: 0 none;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-picklist .p-picklist-header .p-picklist-title {
  font-weight: 600;
}
.p-picklist .p-picklist-filter-container {
  padding: 0.75rem 1rem;
  background: #f8f8f8;
  border: 1px solid #d3d6d7;
  border-bottom: 0 none;
}
.p-picklist .p-picklist-filter-container .p-picklist-filter-input {
  padding-right: 1.75rem;
}
.p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
  right: 0.75rem;
  color: #0b0c0c;
}
.p-picklist .p-picklist-list {
  border: 1px solid #fff;
  background: #f8f8f8;
  color: #0b0c0c;
  padding: 0.75rem 0 0 0;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-picklist .p-picklist-list .p-picklist-item {
  padding: 0.75rem 1rem;
  margin: 0;
  border: 0 none;
  color: #0b0c0c;
  background: transparent;
  transition: box-shadow 0.2s;
}
.p-picklist .p-picklist-list .p-picklist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #ffbf7f;
}
.p-picklist .p-picklist-list .p-picklist-item.p-highlight {
  color: #0b0c0c;
  background: #ffe5cc;
}
.p-picklist .p-picklist-list .p-picklist-empty-message {
  padding: 0.75rem 1rem;
  color: #0b0c0c;
}
.p-picklist .p-picklist-list:not(.cdk-drop-list-dragging) .p-picklist-item:not(.p-highlight):hover {
  background: #f3f2f1;
  color: #0b0c0c;
}
.p-picklist.p-picklist-striped .p-picklist-list .p-picklist-item:nth-child(even) {
  background: #f6f6f6;
}
.p-picklist.p-picklist-striped .p-picklist-list .p-picklist-item:nth-child(even):hover {
  background: #f3f2f1;
}

.p-picklist-item.cdk-drag-preview {
  padding: 0.75rem 1rem;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: 0 none;
  color: #0b0c0c;
  background: #f8f8f8;
  margin: 0;
}

.p-timeline .p-timeline-event-marker {
  border: 2px solid #e56000;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  background-color: #ef6706;
}
.p-timeline .p-timeline-event-connector {
  background-color: #505a5f;
}
.p-timeline.p-timeline-vertical .p-timeline-event-opposite,
.p-timeline.p-timeline-vertical .p-timeline-event-content {
  padding: 0 1rem;
}
.p-timeline.p-timeline-vertical .p-timeline-event-connector {
  width: 2px;
}
.p-timeline.p-timeline-horizontal .p-timeline-event-opposite,
.p-timeline.p-timeline-horizontal .p-timeline-event-content {
  padding: 1rem 0;
}
.p-timeline.p-timeline-horizontal .p-timeline-event-connector {
  height: 2px;
}

.p-tree {
  border: 1px solid #fff;
  background: #f8f8f8;
  color: #0b0c0c;
  padding: 0.75rem 1rem;
  border-radius: 6px;
}
.p-tree .p-tree-container .p-treenode {
  padding: 0.143rem;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content {
  border-radius: 6px;
  transition: box-shadow 0.2s;
  padding: 0.5rem;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
  margin-right: 0.5rem;
  width: 2rem;
  height: 2rem;
  color: #0b0c0c;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
  color: #0b0c0c;
  border-color: transparent;
  background: #f8f8f8;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
  margin-right: 0.5rem;
  color: #0b0c0c;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
  margin-right: 0.5rem;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-indeterminate .p-checkbox-icon {
  color: #0b0c0c;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #ffbf7f;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  background: #ffe5cc;
  color: #0b0c0c;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
  color: #0b0c0c;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon:hover {
  color: #0b0c0c;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  background: #f3f2f1;
  color: #0b0c0c;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
  background: #f3f2f1;
  color: #0b0c0c;
}
.p-tree .p-tree-filter-container {
  margin-bottom: 0.5rem;
}
.p-tree .p-tree-filter-container .p-tree-filter {
  width: 100%;
  padding-right: 1.75rem;
}
.p-tree .p-tree-filter-container .p-tree-filter-icon {
  right: 0.75rem;
  color: #0b0c0c;
}
.p-tree .p-treenode-children {
  padding: 0 0 0 1rem;
}
.p-tree .p-tree-loading-icon {
  font-size: 2rem;
}
.p-tree .p-treenode-droppoint.p-treenode-droppoint-active {
  background-color: #ffb670;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content {
  border-radius: 6px;
  border: 1px solid #fff;
  background-color: #f8f8f8;
  color: #0b0c0c;
  padding: 0.5rem;
  transition: box-shadow 0.2s;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight {
  background-color: #ffe5cc;
  color: #0b0c0c;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
  color: #0b0c0c;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-tree-toggler {
  margin-right: 0.5rem;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-icon {
  color: #0b0c0c;
  margin-right: 0.5rem;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-checkbox {
  margin-right: 0.5rem;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-label:not(.p-highlight):hover {
  background-color: inherit;
  color: inherit;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  background: #f3f2f1;
  color: #0b0c0c;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
}

.p-treetable .p-paginator-top {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-treetable .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-treetable .p-treetable-header {
  background: #f3f2f1;
  color: #0b0c0c;
  border: 1px solid #7c8387;
  border-width: 0px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-treetable .p-treetable-footer {
  background: #fff;
  color: #0b0c0c;
  border: 1px solid #d3d6d7;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-treetable .p-treetable-thead > tr > th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #a7acaf;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #0b0c0c;
  background: #fff;
  transition: box-shadow 0.2s;
}
.p-treetable .p-treetable-tfoot > tr > td {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #d3d6d7;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #0b0c0c;
  background: #fff;
}
.p-treetable .p-sortable-column {
  outline-color: #ffbf7f;
}
.p-treetable .p-sortable-column .p-sortable-column-icon {
  color: #0b0c0c;
  margin-left: 0.5rem;
}
.p-treetable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 0.875rem;
  min-width: 0.875rem;
  line-height: 0.875rem;
  color: #0b0c0c;
  background: #ffe5cc;
  margin-left: 0.5rem;
}
.p-treetable .p-sortable-column:not(.p-highlight):hover {
  background: #fff3e6;
  color: #0b0c0c;
}
.p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: #0b0c0c;
}
.p-treetable .p-sortable-column.p-highlight {
  background: transparent;
  color: #ef6706;
}
.p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #ef6706;
}
.p-treetable .p-treetable-tbody > tr {
  background: #fff;
  color: #0b0c0c;
  transition: box-shadow 0.2s;
}
.p-treetable .p-treetable-tbody > tr > td {
  text-align: left;
  border: 1px solid #d3d6d7;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
  width: 2rem;
  height: 2rem;
  color: #0b0c0c;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
  margin-right: 0.5rem;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:enabled:hover {
  color: #0b0c0c;
  border-color: transparent;
  background: #f8f8f8;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
}
.p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox {
  margin-right: 0.5rem;
}
.p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox .p-indeterminate .p-checkbox-icon {
  color: #0b0c0c;
}
.p-treetable .p-treetable-tbody > tr:focus {
  outline: 0.15rem solid #ffbf7f;
  outline-offset: -0.15rem;
}
.p-treetable .p-treetable-tbody > tr.p-highlight {
  background: #ffe5cc;
  color: #0b0c0c;
}
.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler {
  color: #0b0c0c;
}
.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler:hover {
  color: #0b0c0c;
}
.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover {
  background: #fff3e6;
  color: #0b0c0c;
}
.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover .p-treetable-toggler {
  color: #0b0c0c;
}
.p-treetable .p-column-resizer-helper {
  background: #e56000;
}
.p-treetable .p-treetable-scrollable-header,
.p-treetable .p-treetable-scrollable-footer {
  background: #f3f2f1;
}
.p-treetable .p-treetable-loading-icon {
  font-size: 2rem;
}
.p-treetable.p-treetable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-footer {
  border-width: 0 1px 1px 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-top {
  border-width: 0 1px 0 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-bottom {
  border-width: 0 1px 1px 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-thead > tr > th {
  border-width: 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-tbody > tr > td {
  border-width: 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-tfoot > tr > td {
  border-width: 1px;
}
.p-treetable.p-treetable-sm .p-treetable-header {
  padding: 0.875rem 0.875rem;
}
.p-treetable.p-treetable-sm .p-treetable-thead > tr > th {
  padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-sm .p-treetable-tbody > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-sm .p-treetable-tfoot > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-sm .p-treetable-footer {
  padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-lg .p-treetable-header {
  padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-thead > tr > th {
  padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-tbody > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-tfoot > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-footer {
  padding: 1.25rem 1.25rem;
}

.p-virtualscroller .p-virtualscroller-header {
  background: #f3f2f1;
  color: #0b0c0c;
  border: 1px solid #7c8387;
  border-width: 0px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-virtualscroller .p-virtualscroller-content {
  background: #fff;
  color: #0b0c0c;
  border: 0 none;
  padding: 0;
}
.p-virtualscroller .p-virtualscroller-footer {
  background: #fff;
  color: #0b0c0c;
  border: 1px solid #d3d6d7;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 0.75rem 1rem;
  border: #d3d6d7;
  color: #0b0c0c;
  background: #f8f8f8;
  font-weight: 600;
  border-radius: 6px;
  transition: box-shadow 0.2s;
}
.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  margin-right: 0.5rem;
}
.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
}
.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
  background: #f3f2f1;
  border-color: #d3d6d7;
  color: #0b0c0c;
}
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background: #fff3e6;
  border-color: #d3d6d7;
  color: #0b0c0c;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  border-color: #d3d6d7;
  background: #f3f2f1;
  color: #0b0c0c;
}
.p-accordion .p-accordion-content {
  padding: 1rem;
  border: 1px solid #fff;
  background: #fff;
  color: #0b0c0c;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-accordion p-accordiontab .p-accordion-tab {
  margin-bottom: 4px;
}

.p-card {
  background: #f8f8f8;
  color: #0b0c0c;
  box-shadow: none;
  border-radius: 6px;
}
.p-card .p-card-body {
  padding: 1rem;
}
.p-card .p-card-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.p-card .p-card-subtitle {
  font-weight: 400;
  margin-bottom: 0.5rem;
  color: #0b0c0c;
}
.p-card .p-card-content {
  padding: 1rem 0;
}
.p-card .p-card-footer {
  padding: 1rem 0 0 0;
}

.p-divider .p-divider-content {
  background-color: #f8f8f8;
}
.p-divider.p-divider-horizontal {
  margin: 1rem 0;
  padding: 0 1rem;
}
.p-divider.p-divider-horizontal:before {
  border-top: 1px #d3d6d7;
}
.p-divider.p-divider-horizontal .p-divider-content {
  padding: 0 0.5rem;
}
.p-divider.p-divider-vertical {
  margin: 0 1rem;
  padding: 1rem 0;
}
.p-divider.p-divider-vertical:before {
  border-left: 1px #d3d6d7;
}
.p-divider.p-divider-vertical .p-divider-content {
  padding: 0.5rem 0;
}

.p-fieldset {
  border: 1px solid #fff;
  background: #f8f8f8;
  color: #0b0c0c;
  border-radius: 6px;
}
.p-fieldset .p-fieldset-legend {
  padding: 0.75rem 1rem;
  border: 1px solid #d3d6d7;
  color: #0b0c0c;
  background: #f3f2f1;
  font-weight: 600;
  border-radius: 6px;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
  padding: 0;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
  padding: 0.75rem 1rem;
  color: #0b0c0c;
  border-radius: 6px;
  transition: box-shadow 0.2s;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
  margin-right: 0.5rem;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
  background: #ffe5cc;
  border-color: #ffe5cc;
  color: #0b0c0c;
}
.p-fieldset .p-fieldset-content {
  padding: 0.75rem 1rem;
}

.p-panel .p-panel-header {
  border: 1px solid #d3d6d7;
  padding: 0.75rem 1rem;
  background: #f3f2f1;
  color: #0b0c0c;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-panel .p-panel-header .p-panel-title {
  font-weight: 600;
}
.p-panel .p-panel-header .p-panel-header-icon {
  width: 2rem;
  height: 2rem;
  color: #0b0c0c;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
}
.p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
  color: #0b0c0c;
  border-color: transparent;
  background: #f8f8f8;
}
.p-panel .p-panel-header .p-panel-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
}
.p-panel.p-panel-toggleable .p-panel-header {
  padding: 0.75rem 1rem;
}
.p-panel .p-panel-content {
  padding: 0.75rem 1rem;
  border: 1px solid #fff;
  background: #f8f8f8;
  color: #0b0c0c;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  border-top: 0 none;
}
.p-panel .p-panel-footer {
  padding: 0.75rem 1.25rem;
  border: 1px solid #fff;
  background: #fff;
  color: #0b0c0c;
  border-top: 0 none;
}
.p-panel .p-panel-icons-end {
  order: 2;
  margin-left: auto;
}
.p-panel .p-panel-icons-start {
  order: 0;
  margin-right: 0.5rem;
}
.p-panel .p-panel-icons-center {
  order: 2;
  width: 100%;
  text-align: center;
}

.p-scrollpanel .p-scrollpanel-bar {
  background: #a7acaf;
  border: 0 none;
}

.p-splitter {
  border: 1px solid #fff;
  background: #f8f8f8;
  border-radius: 6px;
  color: #0b0c0c;
}
.p-splitter .p-splitter-gutter {
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
  background: #d3d6d7;
}
.p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
  background: #7c8387;
}
.p-splitter .p-splitter-gutter-resizing {
  background: #7c8387;
}

.p-tabview .p-tabview-nav {
  background: #fcfcfc;
  border: 1px solid #d3d6d7;
  border-width: 0 0 1px 0;
}
.p-tabview .p-tabview-nav li {
  margin-right: 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border: solid #7c8387;
  border-width: 0 0 2px 0;
  border-color: transparent transparent #a7acaf transparent;
  background: #fcfcfc;
  color: #666f73;
  padding: 1rem;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  transition: box-shadow 0.2s;
  margin: 0 0 -2px 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.2rem #ffbf7f;
}
.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  background: #fcfcfc;
  border-color: #7c8387;
  color: #0b0c0c;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #fcfcfc;
  border-color: #e56000;
  font-weight: 600;
}
.p-tabview .p-tabview-left-icon {
  margin-right: 0.5rem;
}
.p-tabview .p-tabview-right-icon {
  margin-left: 0.5rem;
}
.p-tabview .p-tabview-close {
  margin-left: 0.5rem;
}
.p-tabview .p-tabview-nav-btn.p-link {
  background: #fcfcfc;
  color: #ef6706;
  width: 3rem;
  box-shadow:
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 0;
}
.p-tabview .p-tabview-nav-btn.p-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.2rem #ffbf7f;
}
.p-tabview .p-tabview-panels {
  background: #fcfcfc;
  padding: 1rem;
  border: 0 none;
  color: #505a5f;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.p-toolbar {
  background: #f3f2f1;
  border: 1px solid #d3d6d7;
  padding: 0.75rem 1rem;
  border-radius: 6px;
}
.p-toolbar .p-toolbar-separator {
  margin: 0 0.5rem;
}

.p-confirm-popup {
  background: #f8f8f8;
  color: #0b0c0c;
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}
.p-confirm-popup .p-confirm-popup-content {
  padding: 0.75rem 1rem;
}
.p-confirm-popup .p-confirm-popup-footer {
  text-align: right;
  padding: 0.75rem 1.25rem;
}
.p-confirm-popup .p-confirm-popup-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
}
.p-confirm-popup .p-confirm-popup-footer button:last-child {
  margin: 0;
}
.p-confirm-popup:after {
  border: solid transparent;
  border-color: rgba(248, 248, 248, 0);
  border-bottom-color: #f8f8f8;
}
.p-confirm-popup:before {
  border: solid transparent;
  border-color: rgba(248, 248, 248, 0);
  border-bottom-color: #f8f8f8;
}
.p-confirm-popup.p-confirm-popup-flipped:after {
  border-top-color: #f8f8f8;
}
.p-confirm-popup.p-confirm-popup-flipped:before {
  border-top-color: #f8f8f8;
}
.p-confirm-popup .p-confirm-popup-icon {
  font-size: 1.5rem;
}
.p-confirm-popup .p-confirm-popup-message {
  margin-left: 1rem;
}

.p-dialog {
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border: 0 none;
}
.p-dialog .p-dialog-header {
  border-bottom: 0 none;
  background: #fcfcfc;
  color: #0b0c0c;
  padding: 1rem;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 600;
  font-size: 1.25rem;
}
.p-dialog .p-dialog-header .p-dialog-header-icon {
  width: 2rem;
  height: 2rem;
  color: #0b0c0c;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
  margin-right: 0.5rem;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: #0b0c0c;
  border-color: transparent;
  background: #f8f8f8;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
  margin-right: 0;
}
.p-dialog .p-dialog-content {
  background: #f8f8f8;
  color: #0b0c0c;
  padding: 0 1rem 1rem 1rem;
}
.p-dialog .p-dialog-content:last-of-type {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-dialog .p-dialog-footer {
  border-top: 0 none;
  background: #f8f8f8;
  color: #0b0c0c;
  padding: 0 1rem 1rem 1rem;
  text-align: right;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-dialog .p-dialog-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
  font-size: 2rem;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 1rem;
}

.p-overlaypanel {
  background: #f8f8f8;
  color: #0b0c0c;
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}
.p-overlaypanel .p-overlaypanel-content {
  padding: 0.75rem 1rem;
}
.p-overlaypanel .p-overlaypanel-close {
  background: #1a66a8;
  color: #fcfcfc;
  width: 2rem;
  height: 2rem;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
  border-radius: 50%;
  position: absolute;
  top: -1rem;
  right: -1rem;
}
.p-overlaypanel .p-overlaypanel-close:enabled:hover {
  background: #003078;
  color: #fcfcfc;
}
.p-overlaypanel:after {
  border: solid transparent;
  border-color: rgba(248, 248, 248, 0);
  border-bottom-color: #f8f8f8;
}
.p-overlaypanel:before {
  border: solid transparent;
  border-color: rgba(248, 248, 248, 0);
  border-bottom-color: #ececec;
}
.p-overlaypanel.p-overlaypanel-flipped:after {
  border-top-color: #f8f8f8;
}
.p-overlaypanel.p-overlaypanel-flipped:before {
  border-top-color: #f8f8f8;
}

.p-sidebar {
  background: #f8f8f8;
  color: #0b0c0c;
  border: 0 none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}
.p-sidebar .p-sidebar-header {
  padding: 0.75rem 1rem;
}
.p-sidebar .p-sidebar-header .p-sidebar-close,
.p-sidebar .p-sidebar-header .p-sidebar-icon {
  width: 2rem;
  height: 2rem;
  color: #0b0c0c;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
}
.p-sidebar .p-sidebar-header .p-sidebar-close:enabled:hover,
.p-sidebar .p-sidebar-header .p-sidebar-icon:enabled:hover {
  color: #0b0c0c;
  border-color: transparent;
  background: #f8f8f8;
}
.p-sidebar .p-sidebar-header .p-sidebar-close:focus,
.p-sidebar .p-sidebar-header .p-sidebar-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
}
.p-sidebar .p-sidebar-header + .p-sidebar-content {
  padding-top: 0;
}
.p-sidebar .p-sidebar-content {
  padding: 0.75rem 1rem;
}

.p-tooltip .p-tooltip-text {
  background: #0b0c0c;
  color: #fcfcfc;
  padding: 0.5rem;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}
.p-tooltip.p-tooltip-right .p-tooltip-arrow {
  border-right-color: #0b0c0c;
}
.p-tooltip.p-tooltip-left .p-tooltip-arrow {
  border-left-color: #0b0c0c;
}
.p-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: #0b0c0c;
}
.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  border-bottom-color: #0b0c0c;
}

.p-fileupload .p-fileupload-buttonbar {
  background: #f3f2f1;
  padding: 0.75rem 1rem;
  border: 1px solid #d3d6d7;
  color: #0b0c0c;
  border-bottom: 0 none;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-fileupload .p-fileupload-buttonbar .p-button {
  margin-right: 0.5rem;
}
.p-fileupload .p-fileupload-buttonbar .p-button.p-fileupload-choose.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
}
.p-fileupload .p-fileupload-content {
  background: #f8f8f8;
  padding: 2rem 1rem;
  border: 1px solid #fff;
  color: #0b0c0c;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-fileupload .p-progressbar {
  height: 0.25rem;
}
.p-fileupload .p-fileupload-row > div {
  padding: 1rem 1rem;
}
.p-fileupload.p-fileupload-advanced .p-message {
  margin-top: 0;
}

.p-fileupload-choose:not(.p-disabled):hover {
  background: #003078;
  color: #fcfcfc;
  border-color: #003078;
}
.p-fileupload-choose:not(.p-disabled):active {
  background: #002256;
  color: #fcfcfc;
  border-color: #002256;
}

.p-breadcrumb {
  background: #fff;
  border: 1px solid #d3d6d7;
  border-radius: 6px;
  padding: 1rem;
}
.p-breadcrumb ul li .p-menuitem-link {
  transition: box-shadow 0.2s;
  border-radius: 6px;
}
.p-breadcrumb ul li .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
}
.p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
  color: #0b0c0c;
}
.p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon {
  color: #0b0c0c;
}
.p-breadcrumb ul li.p-breadcrumb-chevron {
  margin: 0 0.5rem 0 0.5rem;
  color: #0b0c0c;
}
.p-breadcrumb ul li:last-child .p-menuitem-text {
  color: #0b0c0c;
}
.p-breadcrumb ul li:last-child .p-menuitem-icon {
  color: #0b0c0c;
}

.p-contextmenu {
  padding: 0.25rem 0;
  background: #fff;
  color: #0b0c0c;
  border: 0 none;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  width: 12.5rem;
}
.p-contextmenu .p-menuitem-link {
  padding: 1rem;
  color: #0b0c0c;
  border-radius: 0;
  transition: box-shadow 0.2s;
  user-select: none;
}
.p-contextmenu .p-menuitem-link .p-menuitem-text {
  color: #0b0c0c;
}
.p-contextmenu .p-menuitem-link .p-menuitem-icon {
  color: #0b0c0c;
  margin-right: 0.5rem;
}
.p-contextmenu .p-menuitem-link .p-submenu-icon {
  color: #0b0c0c;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover {
  background: #f8f8f8;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #0b0c0c;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #0b0c0c;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #0b0c0c;
}
.p-contextmenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #ffbf7f;
}
.p-contextmenu .p-submenu-list {
  padding: 0.25rem 0;
  background: #fff;
  border: 0 none;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}
.p-contextmenu .p-menuitem {
  margin: 0;
}
.p-contextmenu .p-menuitem:last-child {
  margin: 0;
}
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #f8f8f8;
}
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #0b0c0c;
}
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #0b0c0c;
}
.p-contextmenu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}
.p-contextmenu .p-submenu-icon {
  font-size: 0.875rem;
}
.p-contextmenu .p-menuitem-badge {
  background: #1a66a8;
  color: #fcfcfc;
  font-size: 0.875rem;
  font-weight: 600;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 6px;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.p-dock .p-dock-list {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0.5rem 0.5rem;
  border-radius: 0.5rem;
}
.p-dock .p-dock-item {
  padding: 0.5rem;
}
.p-dock .p-dock-action {
  width: 4rem;
  height: 4rem;
}
.p-dock.p-dock-top .p-dock-item-second-prev,
.p-dock.p-dock-top .p-dock-item-second-next,
.p-dock.p-dock-bottom .p-dock-item-second-prev,
.p-dock.p-dock-bottom .p-dock-item-second-next {
  margin: 0 0.9rem;
}
.p-dock.p-dock-top .p-dock-item-prev,
.p-dock.p-dock-top .p-dock-item-next,
.p-dock.p-dock-bottom .p-dock-item-prev,
.p-dock.p-dock-bottom .p-dock-item-next {
  margin: 0 1.3rem;
}
.p-dock.p-dock-top .p-dock-item-current,
.p-dock.p-dock-bottom .p-dock-item-current {
  margin: 0 1.5rem;
}
.p-dock.p-dock-left .p-dock-item-second-prev,
.p-dock.p-dock-left .p-dock-item-second-next,
.p-dock.p-dock-right .p-dock-item-second-prev,
.p-dock.p-dock-right .p-dock-item-second-next {
  margin: 0.9rem 0;
}
.p-dock.p-dock-left .p-dock-item-prev,
.p-dock.p-dock-left .p-dock-item-next,
.p-dock.p-dock-right .p-dock-item-prev,
.p-dock.p-dock-right .p-dock-item-next {
  margin: 1.3rem 0;
}
.p-dock.p-dock-left .p-dock-item-current,
.p-dock.p-dock-right .p-dock-item-current {
  margin: 1.5rem 0;
}

@media screen and (max-width: 960px) {
  .p-dock.p-dock-top .p-dock-list-container,
  .p-dock.p-dock-bottom .p-dock-list-container {
    overflow-x: auto;
    width: 100%;
  }
  .p-dock.p-dock-top .p-dock-list-container .p-dock-list,
  .p-dock.p-dock-bottom .p-dock-list-container .p-dock-list {
    margin: 0 auto;
  }
  .p-dock.p-dock-left .p-dock-list-container,
  .p-dock.p-dock-right .p-dock-list-container {
    overflow-y: auto;
    height: 100%;
  }
  .p-dock.p-dock-left .p-dock-list-container .p-dock-list,
  .p-dock.p-dock-right .p-dock-list-container .p-dock-list {
    margin: auto 0;
  }
  .p-dock .p-dock-list .p-dock-item {
    transform: none;
    margin: 0;
  }
}
.p-megamenu {
  padding: 0.5rem;
  background: #fff;
  color: #0b0c0c;
  border: 1px solid #d3d6d7;
  border-radius: 6px;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link {
  padding: 1rem;
  color: #0b0c0c;
  border-radius: 6px;
  transition: box-shadow 0.2s;
  user-select: none;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
  color: #0b0c0c;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
  color: #0b0c0c;
  margin-right: 0.5rem;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
  color: #0b0c0c;
  margin-left: 0.5rem;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
  background: #f8f8f8;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #0b0c0c;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #0b0c0c;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #0b0c0c;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #ffbf7f;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
  background: #f8f8f8;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link:not(.p-disabled):hover
  .p-menuitem-text {
  color: #0b0c0c;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link:not(.p-disabled):hover
  .p-menuitem-icon {
  color: #0b0c0c;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link:not(.p-disabled):hover
  .p-submenu-icon {
  color: #0b0c0c;
}
.p-megamenu .p-menuitem-link {
  padding: 1rem;
  color: #0b0c0c;
  border-radius: 0;
  transition: box-shadow 0.2s;
  user-select: none;
}
.p-megamenu .p-menuitem-link .p-menuitem-text {
  color: #0b0c0c;
}
.p-megamenu .p-menuitem-link .p-menuitem-icon {
  color: #0b0c0c;
  margin-right: 0.5rem;
}
.p-megamenu .p-menuitem-link .p-submenu-icon {
  color: #0b0c0c;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover {
  background: #f8f8f8;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #0b0c0c;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #0b0c0c;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #0b0c0c;
}
.p-megamenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #ffbf7f;
}
.p-megamenu .p-megamenu-panel {
  background: #fff;
  color: #0b0c0c;
  border: 0 none;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.p-megamenu .p-megamenu-submenu-header {
  margin: 0;
  padding: 0.75rem 1.25rem;
  color: #0b0c0c;
  background: #fff;
  font-weight: 600;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-megamenu .p-megamenu-submenu {
  padding: 0.25rem 0;
  width: 12.5rem;
}
.p-megamenu .p-megamenu-submenu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}
.p-megamenu .p-megamenu-submenu .p-menuitem {
  margin: 0;
}
.p-megamenu .p-megamenu-submenu .p-menuitem:last-child {
  margin: 0;
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #f8f8f8;
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #0b0c0c;
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #0b0c0c;
}
.p-megamenu.p-megamenu-vertical {
  width: 12.5rem;
  padding: 0.25rem 0;
}
.p-megamenu.p-megamenu-vertical .p-menuitem {
  margin: 0;
}
.p-megamenu.p-megamenu-vertical .p-menuitem:last-child {
  margin: 0;
}
.p-megamenu .p-menuitem-badge {
  background: #1a66a8;
  color: #fcfcfc;
  font-size: 0.875rem;
  font-weight: 600;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 6px;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.p-menu {
  padding: 0.25rem 0;
  background: #fff;
  color: #0b0c0c;
  border: 1px solid #d3d6d7;
  border-radius: 6px;
  width: 12.5rem;
}
.p-menu .p-menuitem-link {
  padding: 1rem;
  color: #0b0c0c;
  border-radius: 0;
  transition: box-shadow 0.2s;
  user-select: none;
}
.p-menu .p-menuitem-link .p-menuitem-text {
  color: #0b0c0c;
}
.p-menu .p-menuitem-link .p-menuitem-icon {
  color: #0b0c0c;
  margin-right: 0.5rem;
}
.p-menu .p-menuitem-link .p-submenu-icon {
  color: #0b0c0c;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover {
  background: #f8f8f8;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #0b0c0c;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #0b0c0c;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #0b0c0c;
}
.p-menu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #ffbf7f;
}
.p-menu.p-menu-overlay {
  background: #fff;
  border: 0 none;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.p-menu .p-submenu-header {
  margin: 0;
  padding: 0.75rem 1.25rem;
  color: #0b0c0c;
  background: #fff;
  font-weight: 600;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.p-menu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}
.p-menu .p-menuitem {
  margin: 0;
}
.p-menu .p-menuitem:last-child {
  margin: 0;
}
.p-menu .p-menuitem-badge {
  background: #1a66a8;
  color: #fcfcfc;
  font-size: 0.875rem;
  font-weight: 600;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 6px;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.p-menubar {
  padding: 0.5rem;
  background: #fff;
  color: #0b0c0c;
  border: 1px solid #d3d6d7;
  border-radius: 6px;
}
.p-menubar .p-menuitem-link {
  padding: 1rem;
  color: #0b0c0c;
  border-radius: 0;
  transition: box-shadow 0.2s;
  user-select: none;
}
.p-menubar .p-menuitem-link .p-menuitem-text {
  color: #0b0c0c;
}
.p-menubar .p-menuitem-link .p-menuitem-icon {
  color: #0b0c0c;
  margin-right: 0.5rem;
}
.p-menubar .p-menuitem-link .p-submenu-icon {
  color: #0b0c0c;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover {
  background: #f8f8f8;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #0b0c0c;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #0b0c0c;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #0b0c0c;
}
.p-menubar .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #ffbf7f;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
  padding: 1rem;
  color: #0b0c0c;
  border-radius: 6px;
  transition: box-shadow 0.2s;
  user-select: none;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
  color: #0b0c0c;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
  color: #0b0c0c;
  margin-right: 0.5rem;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
  color: #0b0c0c;
  margin-left: 0.5rem;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
  background: #f8f8f8;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #0b0c0c;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #0b0c0c;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #0b0c0c;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #ffbf7f;
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
  background: #f8f8f8;
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
.p-menubar
  .p-menubar-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link:not(.p-disabled):hover
  .p-menuitem-text {
  color: #0b0c0c;
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-menubar
  .p-menubar-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link:not(.p-disabled):hover
  .p-menuitem-icon {
  color: #0b0c0c;
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
.p-menubar
  .p-menubar-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link:not(.p-disabled):hover
  .p-submenu-icon {
  color: #0b0c0c;
}
.p-menubar .p-submenu-list {
  padding: 0.25rem 0;
  background: #fff;
  border: 0 none;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  width: 12.5rem;
}
.p-menubar .p-submenu-list .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}
.p-menubar .p-submenu-list .p-submenu-icon {
  font-size: 0.875rem;
}
.p-menubar .p-submenu-list .p-menuitem {
  margin: 0;
}
.p-menubar .p-submenu-list .p-menuitem:last-child {
  margin: 0;
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #f8f8f8;
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #0b0c0c;
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #0b0c0c;
}
.p-menubar .p-menuitem-badge {
  background: #1a66a8;
  color: #fcfcfc;
  font-size: 0.875rem;
  font-weight: 600;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 6px;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

@media screen and (max-width: 960px) {
  .p-menubar {
    position: relative;
  }
  .p-menubar .p-menubar-button {
    display: flex;
    width: 2rem;
    height: 2rem;
    color: #0b0c0c;
    border-radius: 50%;
    transition:
      background-color 0.2s,
      color 0.2s,
      box-shadow 0.2s;
  }
  .p-menubar .p-menubar-button:hover {
    color: #0b0c0c;
    background: #f8f8f8;
  }
  .p-menubar .p-menubar-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #ffbf7f;
  }
  .p-menubar .p-menubar-root-list {
    position: absolute;
    display: none;
    padding: 0.25rem 0;
    background: #fff;
    border: 0 none;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
  }
  .p-menubar .p-menubar-root-list .p-menu-separator {
    border-top: 1px solid #dee2e6;
    margin: 0.25rem 0;
  }
  .p-menubar .p-menubar-root-list .p-submenu-icon {
    font-size: 0.875rem;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem {
    width: 100%;
    position: static;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    padding: 1rem;
    color: #0b0c0c;
    border-radius: 0;
    transition: box-shadow 0.2s;
    user-select: none;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
    color: #0b0c0c;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
    color: #0b0c0c;
    margin-right: 0.5rem;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
    color: #0b0c0c;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
    background: #f8f8f8;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #0b0c0c;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #0b0c0c;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #0b0c0c;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem #ffbf7f;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
    margin-left: auto;
    transition: transform 0.2s;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-180deg);
  }
  .p-menubar .p-menubar-root-list .p-submenu-list {
    width: 100%;
    position: static;
    box-shadow: none;
    border: 0 none;
  }
  .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
    transition: transform 0.2s;
    transform: rotate(90deg);
  }
  .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-90deg);
  }
  .p-menubar .p-menubar-root-list .p-menuitem {
    width: 100%;
    position: static;
  }
  .p-menubar .p-menubar-root-list ul li a {
    padding-left: 3rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li a {
    padding-left: 5rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li a {
    padding-left: 7rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li ul li a {
    padding-left: 9rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li ul li ul li a {
    padding-left: 11rem;
  }
  .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
    display: flex;
    flex-direction: column;
    top: 100%;
    left: 0;
    z-index: 1;
  }
}
.p-panelmenu .p-panelmenu-header > a {
  padding: 0.75rem 1rem;
  border: #d3d6d7;
  color: #0b0c0c;
  background: #f8f8f8;
  font-weight: 600;
  border-radius: 6px;
  transition: box-shadow 0.2s;
}
.p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-header > a .p-menuitem-icon {
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-header > a:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
}
.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover {
  background: #f3f2f1;
  border-color: #d3d6d7;
  color: #0b0c0c;
}
.p-panelmenu .p-panelmenu-header.p-highlight {
  margin-bottom: 0;
}
.p-panelmenu .p-panelmenu-header.p-highlight > a {
  background: #fff3e6;
  border-color: #d3d6d7;
  color: #0b0c0c;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover {
  border-color: #d3d6d7;
  background: #f3f2f1;
  color: #0b0c0c;
}
.p-panelmenu .p-panelmenu-content {
  padding: 0.25rem 0;
  border: 1px solid #fff;
  background: #fff;
  color: #0b0c0c;
  margin-bottom: 4px;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  padding: 1rem;
  color: #0b0c0c;
  border-radius: 0;
  transition: box-shadow 0.2s;
  user-select: none;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
  color: #0b0c0c;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon {
  color: #0b0c0c;
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-submenu-icon {
  color: #0b0c0c;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
  background: #f8f8f8;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #0b0c0c;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #0b0c0c;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #0b0c0c;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #ffbf7f;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-panelmenu-icon {
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-submenu) {
  padding: 0 0 0 1rem;
}
.p-panelmenu .p-panelmenu-panel {
  margin-bottom: 4px;
}
.p-panelmenu .p-menuitem-badge {
  background: #1a66a8;
  color: #fcfcfc;
  font-size: 0.875rem;
  font-weight: 600;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 6px;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.p-slidemenu {
  padding: 0.25rem 0;
  background: #fff;
  color: #0b0c0c;
  border: 1px solid #d3d6d7;
  border-radius: 6px;
  width: 12.5rem;
}
.p-slidemenu .p-menuitem-link {
  padding: 1rem;
  color: #0b0c0c;
  border-radius: 0;
  transition: box-shadow 0.2s;
  user-select: none;
}
.p-slidemenu .p-menuitem-link .p-menuitem-text {
  color: #0b0c0c;
}
.p-slidemenu .p-menuitem-link .p-menuitem-icon {
  color: #0b0c0c;
  margin-right: 0.5rem;
}
.p-slidemenu .p-menuitem-link .p-submenu-icon {
  color: #0b0c0c;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover {
  background: #f8f8f8;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #0b0c0c;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #0b0c0c;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #0b0c0c;
}
.p-slidemenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #ffbf7f;
}
.p-slidemenu.p-slidemenu-overlay {
  background: #fff;
  border: 0 none;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.p-slidemenu .p-slidemenu-list {
  padding: 0.25rem 0;
  background: #fff;
  border: 0 none;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link {
  background: #f8f8f8;
}
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-text {
  color: #0b0c0c;
}
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon,
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon {
  color: #0b0c0c;
}
.p-slidemenu .p-slidemenu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}
.p-slidemenu .p-slidemenu-icon {
  font-size: 0.875rem;
}
.p-slidemenu .p-slidemenu-backward {
  padding: 1rem;
  color: #0b0c0c;
}
.p-slidemenu .p-menuitem-badge {
  background: #1a66a8;
  color: #fcfcfc;
  font-size: 0.875rem;
  font-weight: 600;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 6px;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.p-steps .p-steps-item .p-menuitem-link {
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 6px;
  background: #f3f2f1;
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  color: #0b0c0c;
  border: 1px solid #7c8387;
  background: #f3f2f1;
  min-width: 2rem;
  height: 2rem;
  line-height: 2rem;
  font-size: 1rem;
  z-index: 1;
  border-radius: 50%;
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  margin-top: 0.5rem;
  color: #0b0c0c;
}
.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
}
.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: #ffe5cc;
  color: #0b0c0c;
}
.p-steps .p-steps-item.p-highlight .p-steps-title {
  font-weight: 600;
  color: #0b0c0c;
}
.p-steps .p-steps-item:before {
  content: ' ';
  border-top: 1px solid #dee2e6;
  width: 100%;
  top: 50%;
  left: 0;
  display: block;
  position: absolute;
  margin-top: -1rem;
}

.p-tabmenu .p-tabmenu-nav {
  background: #fcfcfc;
  border: 1px solid #d3d6d7;
  border-width: 0 0 1px 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
  margin-right: 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  border: solid #7c8387;
  border-width: 0 0 2px 0;
  border-color: transparent transparent #a7acaf transparent;
  background: #fcfcfc;
  color: #666f73;
  padding: 1rem;
  font-weight: 600;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  transition: box-shadow 0.2s;
  margin: 0 0 -2px 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
  margin-right: 0.5rem;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.2rem #ffbf7f;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
  background: #fcfcfc;
  border-color: #7c8387;
  color: #0b0c0c;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  background: #fcfcfc;
  border-color: #e56000;
  color: #ef6706;
}
.p-tabmenu .p-tabmenu-left-icon {
  margin-right: 0.5rem;
}
.p-tabmenu .p-tabmenu-right-icon {
  margin-left: 0.5rem;
}
.p-tabmenu .p-tabmenu-nav-btn.p-link {
  background: #fcfcfc;
  color: #ef6706;
  width: 3rem;
  box-shadow:
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 0;
}
.p-tabmenu .p-tabmenu-nav-btn.p-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.2rem #ffbf7f;
}

.p-tieredmenu {
  padding: 0.25rem 0;
  background: #fff;
  color: #0b0c0c;
  border: 1px solid #d3d6d7;
  border-radius: 6px;
  width: 12.5rem;
}
.p-tieredmenu .p-menuitem-link {
  padding: 1rem;
  color: #0b0c0c;
  border-radius: 0;
  transition: box-shadow 0.2s;
  user-select: none;
}
.p-tieredmenu .p-menuitem-link .p-menuitem-text {
  color: #0b0c0c;
}
.p-tieredmenu .p-menuitem-link .p-menuitem-icon {
  color: #0b0c0c;
  margin-right: 0.5rem;
}
.p-tieredmenu .p-menuitem-link .p-submenu-icon {
  color: #0b0c0c;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover {
  background: #f8f8f8;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #0b0c0c;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #0b0c0c;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #0b0c0c;
}
.p-tieredmenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #ffbf7f;
}
.p-tieredmenu.p-tieredmenu-overlay {
  background: #fff;
  border: 0 none;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.p-tieredmenu .p-submenu-list {
  padding: 0.25rem 0;
  background: #fff;
  border: 0 none;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.p-tieredmenu .p-menuitem {
  margin: 0;
}
.p-tieredmenu .p-menuitem:last-child {
  margin: 0;
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #f8f8f8;
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #0b0c0c;
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #0b0c0c;
}
.p-tieredmenu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}
.p-tieredmenu .p-submenu-icon {
  font-size: 0.875rem;
}
.p-tieredmenu .p-menuitem-badge {
  background: #1a66a8;
  color: #fcfcfc;
  font-size: 0.875rem;
  font-weight: 600;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  border-radius: 6px;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.p-inline-message {
  padding: 0.5rem 0.75rem;
  margin: 0;
  border-radius: 6px;
}
.p-inline-message.p-inline-message-info {
  background: #cce5ff;
  border: solid #1a66a8;
  border-width: 0px;
  color: #1a66a8;
}
.p-inline-message.p-inline-message-info .p-inline-message-icon {
  color: #1a66a8;
}
.p-inline-message.p-inline-message-success {
  background: #ccffdd;
  border: solid #35824f;
  border-width: 0px;
  color: #35824f;
}
.p-inline-message.p-inline-message-success .p-inline-message-icon {
  color: #35824f;
}
.p-inline-message.p-inline-message-warn {
  background: #ffffcc;
  border: solid #f9dd4f;
  border-width: 0px;
  color: #897306;
}
.p-inline-message.p-inline-message-warn .p-inline-message-icon {
  color: #897306;
}
.p-inline-message.p-inline-message-error {
  background: #ffdfdf;
  border: solid #c23019;
  border-width: 0px;
  color: #c23019;
}
.p-inline-message.p-inline-message-error .p-inline-message-icon {
  color: #c23019;
}
.p-inline-message .p-inline-message-icon {
  font-size: 1.25rem;
  margin-right: 0.5rem;
}
.p-inline-message .p-inline-message-text {
  font-size: 1rem;
}
.p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
  margin-right: 0;
}

.p-message {
  margin: 0.5rem 0;
  border-radius: 6px;
}
.p-message .p-message-wrapper {
  padding: 1rem 1.5rem;
}
.p-message .p-message-close {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: transparent;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
}
.p-message .p-message-close:hover {
  background: rgba(255, 255, 255, 0.3);
}
.p-message .p-message-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
}
.p-message.p-message-info {
  background: #cce5ff;
  border: solid #1a66a8;
  border-width: 0 0 0 6px;
  color: #1a66a8;
}
.p-message.p-message-info .p-message-icon {
  color: #1a66a8;
}
.p-message.p-message-info .p-message-close {
  color: #1a66a8;
}
.p-message.p-message-success {
  background: #ccffdd;
  border: solid #35824f;
  border-width: 0 0 0 6px;
  color: #35824f;
}
.p-message.p-message-success .p-message-icon {
  color: #35824f;
}
.p-message.p-message-success .p-message-close {
  color: #35824f;
}
.p-message.p-message-warn {
  background: #ffffcc;
  border: solid #f9dd4f;
  border-width: 0 0 0 6px;
  color: #897306;
}
.p-message.p-message-warn .p-message-icon {
  color: #897306;
}
.p-message.p-message-warn .p-message-close {
  color: #897306;
}
.p-message.p-message-error {
  background: #ffdfdf;
  border: solid #c23019;
  border-width: 0 0 0 6px;
  color: #c23019;
}
.p-message.p-message-error .p-message-icon {
  color: #c23019;
}
.p-message.p-message-error .p-message-close {
  color: #c23019;
}
.p-message .p-message-text {
  font-size: 1rem;
  font-weight: 400;
}
.p-message .p-message-icon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}
.p-message .p-message-summary {
  font-weight: 700;
}
.p-message .p-message-detail {
  margin-left: 0.5rem;
}

.p-toast {
  opacity: 1;
}
.p-toast .p-toast-message {
  margin: 0 0 1rem 0;
  box-shadow: transparent;
  border-radius: 6px;
}
.p-toast .p-toast-message .p-toast-message-content {
  padding: 1rem;
  border-width: 0 0 0 6px;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
  margin: 0 0 0 1rem;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
  font-size: 1.75rem;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
  font-weight: 600;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  margin: 0.5rem 0 0 0;
}
.p-toast .p-toast-message .p-toast-icon-close {
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  background: transparent;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
}
.p-toast .p-toast-message .p-toast-icon-close:hover {
  background: rgba(255, 255, 255, 0.3);
}
.p-toast .p-toast-message .p-toast-icon-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
}
.p-toast .p-toast-message.p-toast-message-info {
  background: #cce5ff;
  border: solid #1a66a8;
  border-width: 0 0 0 6px;
  color: #1a66a8;
}
.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
  color: #1a66a8;
}
.p-toast .p-toast-message.p-toast-message-success {
  background: #ccffdd;
  border: solid #35824f;
  border-width: 0 0 0 6px;
  color: #35824f;
}
.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
  color: #35824f;
}
.p-toast .p-toast-message.p-toast-message-warn {
  background: #ffffcc;
  border: solid #f9dd4f;
  border-width: 0 0 0 6px;
  color: #897306;
}
.p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
  color: #897306;
}
.p-toast .p-toast-message.p-toast-message-error {
  background: #ffdfdf;
  border: solid #c23019;
  border-width: 0 0 0 6px;
  color: #c23019;
}
.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
  color: #c23019;
}

.p-galleria .p-galleria-close {
  margin: 0.5rem;
  background: transparent;
  color: #fcfcfc;
  width: 4rem;
  height: 4rem;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
  border-radius: 50%;
}
.p-galleria .p-galleria-close .p-galleria-close-icon {
  font-size: 2rem;
}
.p-galleria .p-galleria-close:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #fcfcfc;
}
.p-galleria .p-galleria-item-nav {
  background: transparent;
  color: #fcfcfc;
  width: 4rem;
  height: 4rem;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
  border-radius: 6px;
  margin: 0 0.5rem;
}
.p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon,
.p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
  font-size: 2rem;
}
.p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
  background: rgba(255, 255, 255, 0.1);
  color: #fcfcfc;
}
.p-galleria .p-galleria-caption {
  background: rgba(0, 0, 0, 0.5);
  color: #fcfcfc;
  padding: 1rem;
}
.p-galleria .p-galleria-indicators {
  padding: 1rem;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator button {
  background-color: #f8f8f8;
  width: 1rem;
  height: 1rem;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
  border-radius: 50%;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator button:hover {
  background: #f3f2f1;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background: #ffe5cc;
  color: #0b0c0c;
}
.p-galleria.p-galleria-indicators-bottom .p-galleria-indicator,
.p-galleria.p-galleria-indicators-top .p-galleria-indicator {
  margin-right: 0.5rem;
}
.p-galleria.p-galleria-indicators-left .p-galleria-indicator,
.p-galleria.p-galleria-indicators-right .p-galleria-indicator {
  margin-bottom: 0.5rem;
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
  background: rgba(0, 0, 0, 0.5);
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button {
  background: rgba(255, 255, 255, 0.4);
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button:hover {
  background: rgba(255, 255, 255, 0.6);
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background: #ffe5cc;
  color: #0b0c0c;
}
.p-galleria .p-galleria-thumbnail-container {
  background: rgba(0, 0, 0, 0.9);
  padding: 1rem 0.25rem;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
  margin: 0.5rem;
  background-color: transparent;
  color: #fcfcfc;
  width: 2rem;
  height: 2rem;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
  border-radius: 50%;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev:hover,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #fcfcfc;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
}

.p-galleria-mask {
  --maskbg: #fcfcfc;
}

.p-image-mask {
  --maskbg: rgba(0, 0, 0, 0.9);
}

.p-image-preview-indicator {
  background-color: transparent;
  color: #f8f9fa;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
}

.p-image-preview-container:hover > .p-image-preview-indicator {
  background-color: rgba(0, 0, 0, 0.5);
}

.p-image-toolbar {
  padding: 1rem;
}

.p-image-action.p-link {
  color: #f8f9fa;
  background-color: transparent;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
  margin-right: 0.5rem;
}
.p-image-action.p-link:last-child {
  margin-right: 0;
}
.p-image-action.p-link:hover {
  color: #f8f9fa;
  background-color: rgba(255, 255, 255, 0.1);
}
.p-image-action.p-link i {
  font-size: 1.5rem;
}

.p-avatar {
  background-color: #f3f2f1;
  border-radius: 6px;
}
.p-avatar.p-avatar-lg {
  width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
}
.p-avatar.p-avatar-lg .p-avatar-icon {
  font-size: 1.5rem;
}
.p-avatar.p-avatar-xl {
  width: 4rem;
  height: 4rem;
  font-size: 2rem;
}
.p-avatar.p-avatar-xl .p-avatar-icon {
  font-size: 2rem;
}

.p-avatar-group .p-avatar {
  border: 2px solid #f8f8f8;
}

.p-badge {
  background: #1a66a8;
  color: #fcfcfc;
  font-size: 0.875rem;
  font-weight: 600;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
}
.p-badge.p-badge-secondary {
  background-color: #fcfcfc;
  color: #0b0c0c;
}
.p-badge.p-badge-success {
  background-color: #35824f;
  color: #fcfcfc;
}
.p-badge.p-badge-info {
  background-color: #e96025;
  color: #fcfcfc;
}
.p-badge.p-badge-warning {
  background-color: #f9dd4f;
  color: #fcfcfc;
}
.p-badge.p-badge-danger {
  background-color: #c23019;
  color: #fcfcfc;
}
.p-badge.p-badge-lg {
  font-size: 1.3125rem;
  min-width: 2.25rem;
  height: 2.25rem;
  line-height: 2.25rem;
}
.p-badge.p-badge-xl {
  font-size: 1.75rem;
  min-width: 3rem;
  height: 3rem;
  line-height: 3rem;
}

.p-chip {
  background-color: #f3f2f1;
  color: #0b0c0c;
  border-radius: 24px;
  padding: 0 0.75rem;
}
.p-chip .p-chip-text {
  line-height: 1.5;
  margin-top: 0.375rem;
  margin-bottom: 0.375rem;
}
.p-chip .p-chip-icon {
  margin-right: 0.5rem;
}
.p-chip .pi-chip-remove-icon {
  margin-left: 0.5rem;
}
.p-chip img {
  width: 2.25rem;
  height: 2.25rem;
  margin-left: -0.75rem;
  margin-right: 0.5rem;
}
.p-chip .pi-chip-remove-icon {
  border-radius: 6px;
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
}
.p-chip .pi-chip-remove-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
}

.p-inplace .p-inplace-display {
  padding: 0.75rem 0.75rem;
  border-radius: 6px;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
}
.p-inplace .p-inplace-display:not(.p-disabled):hover {
  background: transparent;
  color: #0b0c0c;
}
.p-inplace .p-inplace-display:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffbf7f;
}

.p-progressbar {
  border: 0 none;
  height: 1.5rem;
  background: #d3d6d7;
  border-radius: 6px;
}
.p-progressbar .p-progressbar-value {
  border: 0 none;
  margin: 0;
  background: #e56000;
}
.p-progressbar .p-progressbar-label {
  color: #fcfcfc;
  line-height: 1.5rem;
}

.p-scrolltop {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  transition:
    background-color 0.2s,
    color 0.2s,
    box-shadow 0.2s;
}
.p-scrolltop.p-link {
  background: #2e3336;
}
.p-scrolltop.p-link:hover {
  background: #2e3336;
}
.p-scrolltop .p-scrolltop-icon {
  font-size: 1.5rem;
  color: #fcfcfc;
}

.p-skeleton {
  background-color: #d3d6d7;
  border-radius: 6px;
}
.p-skeleton:after {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
}

.p-tag {
  background: #1a66a8;
  color: #fcfcfc;
  font-size: 0.875rem;
  font-weight: 600;
  padding: 0.25rem 0.75rem;
  border-radius: 6px;
}
.p-tag.p-tag-success {
  background-color: #35824f;
  color: #fcfcfc;
}
.p-tag.p-tag-info {
  background-color: #e96025;
  color: #fcfcfc;
}
.p-tag.p-tag-warning {
  background-color: #f9dd4f;
  color: #fcfcfc;
}
.p-tag.p-tag-danger {
  background-color: #c23019;
  color: #fcfcfc;
}
.p-tag .p-tag-icon {
  margin-right: 0.25rem;
  font-size: 0.875rem;
}

.p-terminal {
  background: #f8f8f8;
  color: #0b0c0c;
  border: 1px solid #fff;
  padding: 0.75rem 1rem;
}
.p-terminal .p-terminal-input {
  font-size: 0.875rem;
  font-family: 'Inter', sans-serif;
}

.ic-file-uploader-panel {
  width: 250px;
  height: 50px;
  transition:
    height 0.25s ease,
    width 0.25s ease,
    padding 0.25s ease;
}
.ic-file-uploader-panel.expanded {
  height: 458px;
  width: 380px;
}
.ic-file-uploader-panel .ic-file-uploader-panel-header {
  transition: height 0.25s ease;
  max-height: 50px;
  height: 50px;
}
.ic-file-uploader-panel .ic-file-uploader-panel-header .pi {
  transition: transform 0.25s ease;
}
.ic-file-uploader-panel .ic-file-uploader-panel-content {
  height: 380px;
  max-height: 380px;
}
.ic-file-uploader-panel.expanded .pi-chevron-up {
  transform: rotate(180deg);
}
.ic-file-uploader-panel.expanded .ic-file-uploader-panel-header {
  max-height: 76px;
  height: 76px;
}

.ic-file-uploader-list .ic-file-uploader-row {
  height: 60px;
}

.p-375 {
  padding: 0.375rem !important;
}

.pt-375 {
  padding-top: 0.375rem !important;
}

.pr-375 {
  padding-right: 0.375rem !important;
}

.pb-375 {
  padding-bottom: 0.375rem !important;
}

.pl-375 {
  padding-left: 0.375rem !important;
}

.py-375 {
  padding-top: 0.375rem !important;
  padding-bottom: 0.375rem !important;
}

.px-375 {
  padding-left: 0.375rem !important;
  padding-right: 0.375rem !important;
}

.p-750 {
  padding: 0.75rem !important;
}

.pt-750 {
  padding-top: 0.75rem !important;
}

.pr-750 {
  padding-right: 0.75rem !important;
}

.pb-750 {
  padding-bottom: 0.75rem !important;
}

.pl-750 {
  padding-left: 0.75rem !important;
}

.py-750 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.px-750 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}

.w-0125rem {
  width: 0.125rem !important;
}

.w-025rem {
  width: 0.25rem !important;
}

.w-0375rem {
  width: 0.375rem !important;
}

.w-0-5rem {
  width: 0.5rem !important;
}

.w-0625rem {
  width: 0.625rem !important;
}

.w-075rem {
  width: 0.75rem !important;
}

.w-0875rem {
  width: 0.875rem !important;
}

.w-1125rem {
  width: 1.125rem !important;
}

.w-125rem {
  width: 1.25rem !important;
}

.w-1375rem {
  width: 1.375rem !important;
}

.w-1-5rem {
  width: 1.5rem !important;
}

.w-1625rem {
  width: 1.625rem !important;
}

.w-175rem {
  width: 1.75rem !important;
}

.w-1875rem {
  width: 1.875rem !important;
}

.w-2125rem {
  width: 2.125rem !important;
}

.w-225rem {
  width: 2.25rem !important;
}

.w-2375rem {
  width: 2.375rem !important;
}

.w-2-5rem {
  width: 2.5rem !important;
}

.w-2625rem {
  width: 2.625rem !important;
}

.w-275rem {
  width: 2.75rem !important;
}

.w-3-5rem {
  width: 3.5rem !important;
}

.h-0125rem {
  height: 0.125rem !important;
}

.h-025rem {
  height: 0.25rem !important;
}

.h-0375rem {
  height: 0.375rem !important;
}

.h-0-5rem {
  height: 0.5rem !important;
}

.h-0625rem {
  height: 0.625rem !important;
}

.h-075rem {
  height: 0.75rem !important;
}

.h-0875rem {
  height: 0.875rem !important;
}

.h-1125rem {
  height: 1.125rem !important;
}

.h-125rem {
  height: 1.25rem !important;
}

.h-1375rem {
  height: 1.375rem !important;
}

.h-1-5rem {
  height: 1.5rem !important;
}

.h-1625rem {
  height: 1.625rem !important;
}

.h-175rem {
  height: 1.75rem !important;
}

.h-1875rem {
  height: 1.875rem !important;
}

.h-2125rem {
  height: 2.125rem !important;
}

.h-225rem {
  height: 2.25rem !important;
}

.h-2375rem {
  height: 2.375rem !important;
}

.h-2-5rem {
  height: 2.5rem !important;
}

.h-2625rem {
  height: 2.625rem !important;
}

.h-275rem {
  height: 2.75rem !important;
}

.h-3-5rem {
  height: 3.5rem !important;
}

.wh-0125rem {
  width: 0.125rem !important;
  height: 0.125rem !important;
}

.wh-025rem {
  width: 0.25rem !important;
  height: 0.25rem !important;
}

.wh-0375rem {
  width: 0.375rem !important;
  height: 0.375rem !important;
}

.wh-0-5rem {
  width: 0.5rem !important;
  height: 0.5rem !important;
}

.wh-0625rem {
  width: 0.625rem !important;
  height: 0.625rem !important;
}

.wh-075rem {
  width: 0.75rem !important;
  height: 0.75rem !important;
}

.wh-0875rem {
  width: 0.875rem !important;
  height: 0.875rem !important;
}

.wh-1125rem {
  width: 1.125rem !important;
  height: 1.125rem !important;
}

.wh-125rem {
  width: 1.25rem !important;
  height: 1.25rem !important;
}

.wh-1375rem {
  width: 1.375rem !important;
  height: 1.375rem !important;
}

.wh-1-5rem {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.wh-1625rem {
  width: 1.625rem !important;
  height: 1.625rem !important;
}

.wh-175rem {
  width: 1.75rem !important;
  height: 1.75rem !important;
}

.wh-1875rem {
  width: 1.875rem !important;
  height: 1.875rem !important;
}

.wh-2rem {
  width: 2rem !important;
  height: 2rem !important;
}

.wh-2125rem {
  width: 2.125rem !important;
  height: 2.125rem !important;
}

.wh-225rem {
  width: 2.25rem !important;
  height: 2.25rem !important;
}

.wh-2375rem {
  width: 2.375rem !important;
  height: 2.375rem !important;
}

.wh-2-5rem {
  width: 2.5rem !important;
  height: 2.5rem !important;
}

.wh-2625rem {
  width: 2.625rem !important;
  height: 2.625rem !important;
}

.wh-275rem {
  width: 2.75rem !important;
  height: 2.75rem !important;
}

.max-w-31rem {
  max-width: 31rem !important;
}

.max-w-32rem {
  max-width: 32rem !important;
}

.max-w-33rem {
  max-width: 33rem !important;
}

.max-w-34rem {
  max-width: 34rem !important;
}

.max-w-35rem {
  max-width: 35rem !important;
}

.max-w-36rem {
  max-width: 36rem !important;
}

.max-w-37rem {
  max-width: 37rem !important;
}

.max-w-38rem {
  max-width: 38rem !important;
}

.max-w-39rem {
  max-width: 39rem !important;
}

.max-w-40rem {
  max-width: 40rem !important;
}

.max-w-41rem {
  max-width: 41rem !important;
}

.max-w-42rem {
  max-width: 42rem !important;
}

.max-w-43rem {
  max-width: 43rem !important;
}

.max-w-44rem {
  max-width: 44rem !important;
}

.max-w-45rem {
  max-width: 45rem !important;
}

.max-w-46rem {
  max-width: 46rem !important;
}

.max-w-47rem {
  max-width: 47rem !important;
}

.max-w-48rem {
  max-width: 48rem !important;
}

.max-w-49rem {
  max-width: 49rem !important;
}

.max-w-50rem {
  max-width: 50rem !important;
}

.max-w-51rem {
  max-width: 51rem !important;
}

.max-w-52rem {
  max-width: 52rem !important;
}

.max-w-53rem {
  max-width: 53rem !important;
}

.max-w-54rem {
  max-width: 54rem !important;
}

.max-w-55rem {
  max-width: 55rem !important;
}

.max-w-56rem {
  max-width: 56rem !important;
}

.max-w-57rem {
  max-width: 57rem !important;
}

.max-w-58rem {
  max-width: 58rem !important;
}

.max-w-59rem {
  max-width: 59rem !important;
}

.max-w-60rem {
  max-width: 60rem !important;
}

.max-w-70rem {
  max-width: 70rem !important;
}

.max-w-80rem {
  max-width: 80rem !important;
}

.max-w-90rem {
  max-width: 90rem !important;
}

.max-w-100rem {
  max-width: 100rem !important;
}

/* Continue for max-height */
.max-h-31rem {
  max-height: 31rem !important;
}

.max-h-32rem {
  max-height: 32rem !important;
}

.max-h-33rem {
  max-height: 33rem !important;
}

.max-h-34rem {
  max-height: 34rem !important;
}

.max-h-35rem {
  max-height: 35rem !important;
}

.max-h-36rem {
  max-height: 36rem !important;
}

.max-h-37rem {
  max-height: 37rem !important;
}

.max-h-38rem {
  max-height: 38rem !important;
}

.max-h-39rem {
  max-height: 39rem !important;
}

.max-h-40rem {
  max-height: 40rem !important;
}

.max-h-41rem {
  max-height: 41rem !important;
}

.max-h-42rem {
  max-height: 42rem !important;
}

.max-h-43rem {
  max-height: 43rem !important;
}

.max-h-44rem {
  max-height: 44rem !important;
}

.max-h-45rem {
  max-height: 45rem !important;
}

.max-h-46rem {
  max-height: 46rem !important;
}

.max-h-47rem {
  max-height: 47rem !important;
}

.max-h-48rem {
  max-height: 48rem !important;
}

.max-h-49rem {
  max-height: 49rem !important;
}

.max-h-50rem {
  max-height: 50rem !important;
}

.max-h-51rem {
  max-height: 51rem !important;
}

.max-h-52rem {
  max-height: 52rem !important;
}

.max-h-53rem {
  max-height: 53rem !important;
}

.max-h-54rem {
  max-height: 54rem !important;
}

.max-h-55rem {
  max-height: 55rem !important;
}

.max-h-56rem {
  max-height: 56rem !important;
}

.max-h-57rem {
  max-height: 57rem !important;
}

.max-h-58rem {
  max-height: 58rem !important;
}

.max-h-59rem {
  max-height: 59rem !important;
}

.max-h-60rem {
  max-height: 60rem !important;
}

.max-h-70rem {
  max-height: 70rem !important;
}

.max-h-80rem {
  max-height: 80rem !important;
}

.max-h-90rem {
  max-height: 90rem !important;
}

.max-h-100rem {
  max-height: 100rem !important;
}

.p-3-5 {
  padding: 3.5rem !important;
}

.pl-3-5 {
  padding-left: 3.5rem !important;
}

.pr-3-5 {
  padding-right: 3.5rem !important;
}

.pt-3-5 {
  padding-top: 3.5rem !important;
}

.pb-3-5 {
  padding-bottom: 3.5rem !important;
}

.px-3-5 {
  padding-left: 3.5rem !important;
  padding-right: 3.5rem !important;
}

.py-3-5 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

/* Height Classes */
.h-10 {
  height: 10% !important;
}

.h-20 {
  height: 20% !important;
}

.h-30 {
  height: 30% !important;
}

.h-40 {
  height: 40% !important;
}

.h-50 {
  height: 50% !important;
}

.h-60 {
  height: 60% !important;
}

.h-70 {
  height: 70% !important;
}

.h-80 {
  height: 80% !important;
}

.h-90 {
  height: 90% !important;
}

.h-95 {
  height: 95% !important;
}

/* Width Classes */
.w-10 {
  width: 10% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.w-95 {
  width: 95% !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

.z-4 {
  z-index: 4 !important;
}

.z-5 {
  z-index: 5 !important;
}

.z-10 {
  z-index: 10 !important;
}

.z-20 {
  z-index: 20 !important;
}

.z-30 {
  z-index: 30 !important;
}

.z-40 {
  z-index: 40 !important;
}

.z-50 {
  z-index: 50 !important;
}

.z-100 {
  z-index: 100 !important;
}

.z-200 {
  z-index: 200 !important;
}

.z-300 {
  z-index: 300 !important;
}

.z-400 {
  z-index: 400 !important;
}

.z-500 {
  z-index: 500 !important;
}

.z-top {
  z-index: 75000 !important;
}

.text-wrap-nowrap {
  text-wrap: nowrap !important;
}

.text-elevation-2 {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.text-elevation-5 {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.white-space-nowrap {
  white-space: nowrap;
}

.white-space-pre {
  white-space: pre;
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}

.white-space-pre-line {
  white-space: pre-line;
}

.container-rtl {
  direction: rtl;
}

.container-rtl > * {
  direction: ltr;
}

.overflow-wrap {
  overflow-wrap: break-word !important;
}

.overflow-wrap-normal {
  overflow-wrap: normal !important;
}

.overflow-wrap-anywhere {
  overflow-wrap: anywhere !important;
}

.overflow-wrap-overflow {
  overflow-wrap: overflow !important;
}

.overflow-wrap-keep-all {
  overflow-wrap: keep-all !important;
}

.text-ellipsis-clamp-1 {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.blur-25 {
  filter: blur(0.25rem);
}

.blur-5 {
  filter: blur(0.5rem);
}

.blur-75 {
  filter: blur(0.75rem);
}

.blur-1 {
  filter: blur(1rem);
}

.blur-none {
  filter: blur(0);
}

.blur-0-hover:hover {
  filter: blur(0) !important;
}

/* Customizations to the designer theme should be defined here */
body,
.p-component {
  font-family: 'Inter', sans-serif !important;
}

.mono-font {
  font-family: 'IBM Plex Mono', monospace !important;
}

/* ########################Buttons############################ */
.p-button {
  font-weight: 600;
  justify-content: center;
}
.p-button.p-button-text-underline > span {
  border-bottom: 1px solid currentColor;
}

button[disabled]:not(.p-element) {
  background-color: #d3d6d7;
  filter: grayscale(1);
}

.p-button:disabled,
.p-button.disabled {
  background: #d3d6d7;
  border: 1px solid #d3d6d7;
  color: #505a5f;
  opacity: unset;
}

.p-button.p-button-icon-only.p-button-rounded {
  height: auto;
}

.p-dialog .p-dialog-header {
  padding: 0.75rem !important;
}

.p-dialog .p-dialog-header .p-dialog-title {
  font-size: 1rem;
  font-weight: 600;
}

.p-button:enabled:focus,
.p-buttonset > .p-button:enabled:focus,
.p-splitbutton > .p-button:enabled:focus,
.p-button:focus {
  box-shadow: 0 0 0 0.2rem #88beed;
}

.p-button.p-button-lg {
  padding: 0.9375rem 1.5625rem !important;
}

.p-button-label {
  flex: none !important;
}

.p-button-link {
  padding: 0 !important;
}

.p-button.p-button-secondary.p-button-outlined,
.p-button.p-button-secondary.p-button-text {
  color: #0b0c0c;
}

.p-button.p-button-secondary.p-button-outlined {
  background: rgba(100, 116, 139, 0.1);
  border: 2px solid #bcc3cd !important;
  border-radius: 6px !important;
}

.p-button.p-button-xs.p-button-icon-only {
  width: 1.5rem !important;
  height: 1.5rem !important;
  padding: 0 !important;
}

.p-button.p-button-secondary.p-button-text:enabled:hover,
.p-button.p-button-secondary.p-button-outlined:enabled:hover {
  color: #505a5f !important;
}

.p-button.p-button-danger.p-button-outlined,
.p-button.p-button-danger.p-button-text {
  border-color: #c23019;
  color: #c23019;
}

.p-button-nav span {
  font-size: 0.875rem;
}

/* ##########################Tag############################## */
.p-tag.p-tag-rounded {
  border-radius: 10rem;
}

.p-tag.p-tag-success {
  background: #ccffdd;
  color: #35824f;
}

.p-tag.p-tag-danger {
  background: #ffdfdf;
  color: #c23019;
}

/* ######################ProgressBar########################## */
.p-progressbar {
  height: 0.5rem !important;
}

/* ##########################InputSwitch############################ */
.p-inputswitch.p-inputswitch-checked.p-disabled .p-inputswitch-slider {
  background-color: #d3d6d7 !important;
}

/* #########################Inputs############################ */
.p-inputnumber-buttons-stacked .p-button.p-inputnumber-button-up,
.p-inputnumber-buttons-stacked .p-button.p-inputnumber-button-down {
  padding: 0 !important;
}

.p-inputnumber-button-group {
  height: 2.5rem !important;
}

input::placeholder,
textarea::placeholder {
  color: #666f73;
}
input:disabled,
textarea:disabled {
  background: #d3d6d7;
  color: #505a5f;
}
input:disabled::placeholder,
textarea:disabled::placeholder {
  color: #d3d6d7;
}

label[disabled='true'].radio-button {
  background: #d3d6d7 !important;
  color: #505a5f !important;
  pointer-events: none !important;
  user-select: none !important;
}

p-password .p-inputtext {
  font-family: 'IBM Plex Mono', monospace !important;
}

/* ########################Dropdowns########################## */
.p-dropdown-item {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 56px;
  white-space: normal;
}

/* ##########################Chips############################ */
.p-chips .p-chips-multiple-container .p-chips-token {
  margin: 0 !important;
}

.p-chips .p-chips-multiple-container {
  gap: 0.25rem !important;
  max-height: 400px !important;
  overflow: auto !important;
}

/* #######################MultiSelect######################### */
p-multiselect .p-dropdown-label,
p-dropdown .p-dropdown-label {
  text-overflow: ellipsis;
}
p-multiselect .p-disabled,
p-multiselect .p-component:disabled,
p-dropdown .p-disabled,
p-dropdown .p-component:disabled {
  background: #d3d6d7;
  color: #505a5f;
}
p-multiselect .p-disabled .p-multiselect-token,
p-multiselect .p-component:disabled .p-multiselect-token,
p-dropdown .p-disabled .p-multiselect-token,
p-dropdown .p-component:disabled .p-multiselect-token {
  background: #f3f2f1 !important;
}

.p-multiselect-chip .p-multiselect-label {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem 0rem;
}
.p-multiselect-chip .p-multiselect-token {
  padding: 0.5rem 0.75rem;
}

/* ########################Overlays########################### */
.p-sidebar-content {
  flex: 1;
}

.p-sidebar {
  background: #fff;
}

/* ########################Messages########################### */
.p-message .p-message-close,
.p-toast .p-toast-message .p-toast-icon-close {
  min-width: 2rem;
  min-height: 2rem;
}

.p-inline-message {
  justify-content: flex-start;
}

/* ########################Dialogs############################ */
.p-slider.p-slider-horizontal .p-slider-handle {
  margin-top: -10px !important;
  margin-left: -10px !important;
}

/* ########################Dialogs############################ */
.p-dialog-bottom-left .p-dialog,
.p-dialog-bottom-right .p-dialog {
  max-height: 75%;
}

.p-dialog .p-dialog-footer {
  padding-top: 0.75rem !important;
}

.p-dialog-content > * {
  justify-content: space-between;
}

.p-dialog-content > * > .dialog-content {
  overflow-x: hidden;
  display: flex;
  height: calc(100% - 54px);
  margin-bottom: 0.5rem;
}

@media screen and (max-width: 769px) {
  .p-dialog-content > * > .dialog-content {
    overflow-x: hidden;
    display: flex;
    margin-bottom: 0.5rem;
    height: unset;
  }
}
@media (max-width: 767px) {
  .p-dialog.full-screen-mobile {
    max-height: 100% !important;
    max-width: 100% !important;
    width: 100vw !important;
    margin: 0 !important;
    height: 100vh !important;
  }
}
.p-dialog-content > * > .dialog-footer {
  display: flex;
}

/* ########################Treenode########################### */
.p-treenode-content .p-treenode-label a {
  color: #505a5f;
}
.p-treenode-content .p-treenode-content.p-highlight {
  border-radius: 0;
  border: 0;
  background: #ffe5cc;
}
.p-treenode-content .p-highlight .p-treenode-label a {
  color: #0b0c0c;
}

/* #######################TreeTable########################### */
.p-treetable-toggler {
  margin: 0 !important;
}

.p-treetable .p-treetable-tbody > tr > td {
  padding: 1rem 1rem;
  text-align: left;
  border: hidden;
}

.p-treetable-tbody {
  display: flex;
  flex-direction: column;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.tree-node-first:not(:first-of-type) {
  margin-top: 1rem;
}

/* #################CheckboxSelectButton###################### */
.checkbox-select-button {
  flex-direction: column;
  display: flex;
  gap: 0.5rem;
}

.checkbox-select-button > div {
  background-color: #fcfcfc;
  border: 1px solid #d3d6d7;
  flex-direction: row;
  align-items: center;
  color: #37404c;
  display: flex;
  border-radius: 6px;
  padding: 0.75rem;
  gap: 0.5rem;
}

.checkbox-select-button > div > label {
  align-items: center;
  display: flex;
  gap: 0.25rem;
}

.checkbox-select-button > div:hover {
  border: 1px solid #ffe5cc;
}

.checkbox-select-button-highlighted {
  border: 1px solid #e56000 !important;
  background-color: #fff3e6 !important;
}

/* ##########################Panel############################ */
.p-panel .p-panel-header {
  background-color: #fcfcfc;
}

.p-panel .p-panel-content {
  padding: 1rem;
  border: 1px solid #d3d6d7;
  border-top: 0 none;
  background: transparent;
  color: inherit;
}

/* ########################Galleria########################### */
.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background: #e56000;
}

.p-galleria .p-galleria-indicators .p-galleria-indicator button {
  background: #d3d6d7;
}

.p-galleria .p-galleria-caption {
  color: unset !important;
  background: #fcfdfd;
  position: relative;
  padding: 0.5rem 0;
}

.p-galleria-item-container {
  flex-direction: column;
}

/* #########################DragDrop########################## */
.cdk-drag-preview {
  z-index: 9999 !important;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drag-container.cdk-drop-list-dragging .drag-item:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/* ########################IslaTheme########################## */
.p-calendar-w-btn .p-datepicker-trigger,
.p-calendar-w-btn .p-datepicker-trigger:hover {
  background: #fcfcfc;
  border: 1px solid #d3d6d7;
  color: #0b0c0c;
  border-left: none;
  box-shadow: none;
}

.p-datepicker table td.p-datepicker-today > span {
  background: transparent;
  color: #0b0c0c;
  border-color: #e56000;
  border-width: 2px;
}
.p-datepicker table td:hover > span {
  background: #f3f2f1 !important;
}
.p-datepicker table td > span.p-disabled {
  color: #666f73;
}

.p-listbox {
  border: none !important;
  background: unset !important;
}
.p-listbox li {
  padding: 0.5rem !important;
}

.p-listbox:not(.p-disabled) .p-listbox-item:not(.p-disabled):hover {
  cursor: pointer;
}

.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
  background: #fff3e6;
}

.patient-views-sidebar .p-sidebar-content {
  padding: 0;
}

.p-datatable {
  border: 1px solid #a7acaf;
  border-bottom: none;
  border-radius: 6px 6px 0px 0px;
}

.p-datatable .p-datatable-header {
  background: #f3f2f1;
  border-bottom: 1px solid #a7acaf !important;
  border: none;
  border-radius: 6px 6px 0px 0px;
}

.p-datatable .p-datatable-thead > tr > th {
  background: #f3f2f1;
  border: none;
  border-bottom: 1px solid #a7acaf;
}

.p-datatable .p-sortable-column:not(.p-highlight):hover {
  background: #f3f2f1;
  color: #37404c;
}

.p-datatable .p-datatable-tbody > tr > td {
  border-bottom: 1px solid #a7acaf;
}

.p-datatable .p-datatable-tbody > tr:hover {
  background: linear-gradient(0deg, rgba(55, 64, 76, 0.02), rgba(55, 64, 76, 0.02)), #f3f2f1;
}

.p-datatable .p-datatable-tbody > tr:focus {
  outline-offset: 0rem;
}

.bg-bluegray-50:hover {
  background: linear-gradient(0deg, rgba(55, 64, 76, 0.02), rgba(55, 64, 76, 0.02)), #f3f2f1 !important;
}

.p-datatable .p-sortable-column.p-highlight {
  background: #f3f2f1;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  margin-left: 0 !important;
}

.p-card {
  border: 1px solid #d3d6d7;
}

.p-card .p-card-body {
  padding: 1rem;
}

.pi-upload:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffe5cc;
  border-color: #fff3e6;
  border-radius: 6px;
}

.ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ic-element:hover {
  box-shadow:
    0px 4px 12px 0px rgba(0, 0, 0, 0.25),
    1px 1px 4px 1px rgba(0, 0, 0, 0.1) !important;
}

.ic-selected {
  box-shadow:
    0px 4px 12px 0px rgba(0, 0, 0, 0.25),
    1px 1px 4px 1px rgba(0, 0, 0, 0.1) !important;
  outline: 2px solid #ffe5cc !important;
  background: #fff3e6 !important;
}

/* ########################Tabview############################ */
/* ####################Tabview Dialog######################### */
.p-dialog-header,
.p-dialog-content,
.p-dialog-footer {
  background: #f8f8f8 !important;
}

.p-dialog-content .p-tabview-nav-container,
.p-dialog-content .p-tabview-nav-link,
.p-dialog-content .p-tabview-panels,
.p-dialog-content .p-tabview-nav {
  background: inherit !important;
}
.p-dialog-content .p-tabview-nav-content {
  margin: 0 -1rem;
}
.p-dialog-content .p-tabview .p-tabview-panels {
  padding: 1rem 0;
}
.p-dialog-content .p-tabview-nav {
  padding: 0 1rem;
}
.p-dialog-content .p-tabview .p-tabview-nav {
  border-color: #d3d6d7 !important;
}
.p-dialog-content .p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border-color: #bcc3cd !important;
}
.p-dialog-content .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: #e56000;
  border-color: #e56000 !important;
}

/* #####################Patient Record######################## */
.patient-record-folder-list .p-tree .p-treenode-children,
.patient-record-folder-list .p-tree .p-tree-container .p-treenode {
  padding: 0 !important;
}
.patient-record-folder-list .p-treenode-leaf > .p-treenode-content .p-tree-toggler {
  padding: 0 !important;
  margin: 0 !important;
}
.patient-record-folder-list .p-tree .p-tree-container .p-treenode .p-treenode-content {
  padding: 1rem !important;
}
.patient-record-folder-list .p-treenode-label {
  width: 100% !important;
}
.patient-record-folder-list .p-tree-container {
  overflow: inherit !important;
}

/* ########################General############################ */
.blue-gradient-bg {
  background-image: linear-gradient(
    to right bottom,
    #03749c,
    #2e82a8,
    #4791b5,
    #5da0c1,
    #71afce,
    #7ab6d4,
    #82bdd9,
    #8bc4df,
    #8bc4df,
    #8bc4df,
    #8bc4df,
    #8bc4df
  );
}

.grayscale-100 {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.no-focus:focus {
  outline: none !important;
  box-shadow: none !important;
}

.vertical-column {
  writing-mode: vertical-rl;
  transform: rotate(0deg);
}

.text-wrap {
  text-overflow: ellipsis;
  width: inherit;
  white-space: nowrap;
  overflow: hidden;
}

.p-tooltip {
  max-width: 25rem !important;
}

.word-break-all {
  word-break: break-all;
}

.word-break-word {
  word-break: break-word;
}

.object-fit-contain {
  object-fit: contain;
}

.object-fit-cover {
  object-fit: cover;
}

.overscroll-behavior-contain {
  overscroll-behavior: contain;
}

.p-sidebar .p-sidebar-header + .p-sidebar-content {
  padding-top: 1rem !important;
}

.p-button-icon-only-xs:has(.p-button-icon):not(:has(.p-button-label)) {
  padding: 0 !important;
}

.p-image-mask {
  z-index: 99999 !important;
}

.p-toast {
  width: 20rem !important;
}

.p-message svg.p-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.p-disabled,
.p-component:disabled {
  opacity: unset !important;
}

.banner-sm .p-message {
  padding: 0 !important;
  margin: 0 !important;
}
.banner-sm .p-message .p-message-wrapper {
  padding: 0.5rem 0.75rem !important;
}
.banner-sm .p-message .p-message-close {
  min-height: 1rem;
  height: 1rem;
  min-width: 1rem;
  width: 1rem;
}
.banner-sm .p-message .p-message-icon {
  display: flex;
}
.banner-sm.banner-square .p-message {
  border-radius: 0 !important;
}

.p-divider.p-divider-horizontal::before {
  border-top-style: solid !important;
}

.p-divider.p-divider-vertical::before {
  border-left-style: solid !important;
}

.p-overlaypanel {
  margin-top: 0 !important;
}

.p-overlaypanel-close {
  background-color: #f8f8f8 !important;
  border: 1px solid #d3d6d7 !important;
  color: #0b0c0c !important;
}
.p-overlaypanel-close:hover {
  background-color: #f3f2f1 !important;
  border-color: #a7acaf !important;
}

.p-component-overlay:has(.interactable-overlay) {
  background-color: transparent !important;
  pointer-events: none !important;
}

a {
  color: #1a66a8;
}

/* #########################NgSelect############################ */
.ng-select {
  text-align: left;
}
.ng-select.ng-select-disabled > .ng-select-container {
  background-color: #f3f2f1 !important;
}
.ng-select.ng-select-disabled > .ng-select-container .ng-value-container .ng-value {
  background-color: #f3f2f1 !important;
}
.ng-select .ng-value-label {
  max-width: 12rem;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* #########################Menu############################ */
.p-menu .p-menuitem-link {
  color: inherit;
}
.p-menu .p-menuitem-link .p-menuitem-icon {
  color: inherit;
}
.p-menu .p-menuitem-link .p-menuitem-text {
  color: inherit;
}

/* #########################Messages############################ */
.p-inline-message .p-inline-message-text {
  font-size: 0.875rem;
}

/* #########################Confirm Dialog############################ */
p-confirmdialog {
  text-align: left;
}
p-confirmdialog .p-dialog-footer {
  display: flex;
  justify-content: space-between;
}

/* #########################Responsive dropdown drawer############################ */
.p-sidebar.dropdown-options-drawer {
  border-radius: 6px;
  background-color: #fcfcfc;
}
.p-sidebar.dropdown-options-drawer .p-sidebar-header {
  padding-bottom: 0;
}
.p-sidebar.dropdown-options-drawer .p-listbox {
  border: 1px solid #d3d6d7 !important;
}
.p-sidebar.dropdown-options-drawer .p-listbox-list {
  padding: 0 !important;
}
.p-sidebar.dropdown-options-drawer .p-listbox-item {
  padding: 16px !important;
  height: 44px;
  border: 2px solid transparent;
  font-size: 1rem;
  line-height: 1.25rem;
}
.p-sidebar.dropdown-options-drawer .p-listbox-item.p-highlight {
  border: 2px solid #ffbf7f;
}

/* #########################Fieldset############################ */
fieldset {
  padding: 0;
}

.p-fieldset {
  border: 1px solid #d3d6d7;
  border-radius: 6px;
  padding: 12px;
  background: transparent;
}
.p-fieldset .p-fieldset-content {
  padding: 0;
}
.p-fieldset .p-fieldset-legend {
  border: 0 none;
  padding: 0;
  background: transparent;
  font-weight: 600;
  border-radius: 6px;
}

@media (max-width: 767px) {
  .dynamic-dialog-full-screen-mask {
    align-items: end !important;
  }

  .dynamic-dialog-full-screen {
    margin: 0 !important;
    max-height: 90% !important;
    border-radius: 0 !important;
  }
  .dynamic-dialog-full-screen .p-dialog-content {
    padding: 0 1rem 1rem 1rem !important;
  }
}
.auth-container .p-password-meter {
  display: none;
}

.clinician-submission-dialog ic-clinician-submission-consent {
  max-width: 648px;
}
.clinician-submission-dialog .p-tabview .p-highlight .p-tabview-nav-link {
  border-color: #e56000 !important;
  color: #ef6706 !important;
}
.clinician-submission-dialog .p-tabview-nav-content,
.clinician-submission-dialog .p-tabview-panels,
.clinician-submission-dialog .p-tabview-nav {
  background: #fcfcfc !important;
}
.clinician-submission-dialog .p-tabview-nav-content {
  border-bottom: 1px solid #d3d6d7 !important;
}
.clinician-submission-dialog .p-tabview-panels {
  padding: 0 !important;
}
.clinician-submission-dialog .p-tabview-nav-link {
  background: transparent !important;
}

.user-dashboard-container strong,
.primeng-override strong,
.clinician-submission-dialog strong,
.submission-container strong,
.submissions-dashboard-container strong,
.auth-container strong,
.team-container strong,
.patient-views-container strong,
.patient-record strong {
  font-weight: 600 !important;
}
.user-dashboard-container a:not(.p-tabview-nav-link, .p-menuitem-link, .sub-folder),
.primeng-override a:not(.p-tabview-nav-link, .p-menuitem-link, .sub-folder),
.clinician-submission-dialog a:not(.p-tabview-nav-link, .p-menuitem-link, .sub-folder),
.submission-container a:not(.p-tabview-nav-link, .p-menuitem-link, .sub-folder),
.submissions-dashboard-container a:not(.p-tabview-nav-link, .p-menuitem-link, .sub-folder),
.auth-container a:not(.p-tabview-nav-link, .p-menuitem-link, .sub-folder),
.team-container a:not(.p-tabview-nav-link, .p-menuitem-link, .sub-folder),
.patient-views-container a:not(.p-tabview-nav-link, .p-menuitem-link, .sub-folder),
.patient-record a:not(.p-tabview-nav-link, .p-menuitem-link, .sub-folder) {
  text-decoration: underline;
}
.user-dashboard-container .p-inputwrapper input,
.primeng-override .p-inputwrapper input,
.clinician-submission-dialog .p-inputwrapper input,
.submission-container .p-inputwrapper input,
.submissions-dashboard-container .p-inputwrapper input,
.auth-container .p-inputwrapper input,
.team-container .p-inputwrapper input,
.patient-views-container .p-inputwrapper input,
.patient-record .p-inputwrapper input {
  width: 100%;
}
.user-dashboard-container .container,
.primeng-override .container,
.clinician-submission-dialog .container,
.submission-container .container,
.submissions-dashboard-container .container,
.auth-container .container,
.team-container .container,
.patient-views-container .container,
.patient-record .container {
  max-width: 1280px;
  margin: 0 auto;
}
.user-dashboard-container .hover-clickable,
.primeng-override .hover-clickable,
.clinician-submission-dialog .hover-clickable,
.submission-container .hover-clickable,
.submissions-dashboard-container .hover-clickable,
.auth-container .hover-clickable,
.team-container .hover-clickable,
.patient-views-container .hover-clickable,
.patient-record .hover-clickable {
  cursor: pointer;
  transition: transform 0.3s ease;
}
.user-dashboard-container .hover-clickable:hover,
.primeng-override .hover-clickable:hover,
.clinician-submission-dialog .hover-clickable:hover,
.submission-container .hover-clickable:hover,
.submissions-dashboard-container .hover-clickable:hover,
.auth-container .hover-clickable:hover,
.team-container .hover-clickable:hover,
.patient-views-container .hover-clickable:hover,
.patient-record .hover-clickable:hover {
  transform: scale(1.05);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.p-panel-content a {
  text-decoration: underline;
}

.text-ellipsis div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

ic-rd-patient-label-selection .p-overlay-content {
  transform-origin: inherit !important;
  height: calc(100vh - 200px) !important;
  overflow: auto !important;
}

.attach-resource-file-dialog .media-display-image-container {
  width: 100% !important;
}

.medwise-btn-img {
  height: 1.25rem !important;
}

.p-buttonset .p-button:first-of-type {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.p-buttonset .p-button:last-of-type {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.p-buttonset .p-button:not(:first-of-type):not(:last-of-type) {
  border-radius: 0;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.p-inputgroup-addon:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-inputgroup-addon:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.p-inputgroup button:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-inputgroup button:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.p-inputgroup input:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-inputgroup input:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.p-inputgroup > .p-inputwrapper:first-child > .p-component {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-inputgroup > .p-inputwrapper:first-child > .p-component > .p-inputtext {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-inputgroup > .p-inputwrapper:last-child > .p-component {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.p-inputgroup > .p-inputwrapper:last-child > .p-component > .p-inputtext {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext {
  border-radius: 0;
  margin: 0;
}
.p-inputgroup > .p-component {
  border-radius: 0;
  margin: 0;
}
.p-inputgroup > .p-element {
  border-radius: 0;
  margin: 0;
}
.p-inputgroup > .p-float-label > .p-component {
  border-radius: 0;
  margin: 0;
}

.p-splitbutton .p-splitbutton-defaultbutton {
  flex: 1 1 auto;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0 none;
}

.p-splitbutton.p-button-rounded > .p-splitbutton-defaultbutton.p-button {
  flex: 1 1 auto;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0 none;
}
.p-splitbutton.p-button-rounded > .p-splitbutton-menubutton.p-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.p-splitbutton.p-button-outlined > .p-splitbutton-defaultbutton.p-button {
  flex: 1 1 auto;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0 none;
}
.p-splitbutton.p-button-outlined > .p-splitbutton-menubutton.p-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.p-splitbutton-menubutton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.p-calendar-w-btn .p-inputtext {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.p-calendar-w-btn .p-datepicker-trigger {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.p-inputnumber-buttons-stacked .p-inputnumber-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.p-inputnumber-buttons-stacked .p-button.p-inputnumber-button-up {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0;
}
.p-inputnumber-buttons-stacked .p-button.p-inputnumber-button-down {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  padding: 0;
}

@media screen and (max-width: 810px) {
  .bespoke-calc-dialog ic-clinical-form-wrapper-section .p-element + div.p-4 {
    padding: 0.5rem !important;
  }
  .bespoke-calc-dialog .mat-mdc-card {
    padding-left: 0;
    padding-right: 0;
  }
  .bespoke-calc-dialog .p-inputnumber-input {
    width: 100%;
  }
}

vg-overlay-play {
  z-index: 4 !important;
}

.p-galleria-thumbnail-item-current .thumbnail-image {
  border: 1px solid #e56000 !important;
}

.patient-record .p-toolbar {
  padding: 0;
}
.patient-record .p-tabview .p-tabview-nav-btn {
  backdrop-filter: blur(5rem);
}
.patient-record .p-tabview .p-tabview-nav {
  border-color: transparent !important;
  background-color: transparent !important;
}
.patient-record .p-tabview,
.patient-record .p-tabview li > a {
  background-color: transparent !important;
}
.patient-record .p-tabview.tabview-hide-content .p-tabview-panels {
  display: none;
}
.patient-record .message-no-spacing .p-message {
  padding: 0 !important;
  margin: 0 !important;
}

.entry-item-dialog .p-dialog-footer button {
  margin: 0;
  width: auto;
}
.entry-item-dialog .p-galleria-content {
  height: 100%;
}
.entry-item-dialog .p-galleria-content p-galleriaitem {
  display: none;
}
.entry-item-dialog .p-galleria-thumbnail-items-container {
  width: 6.375rem !important;
  flex: 1 !important;
}
.entry-item-dialog .p-galleria-thumbnail-items-container .p-galleria-thumbnail-item {
  overflow: hidden !important;
}
.entry-item-dialog p-galleriacontent,
.entry-item-dialog p-galleriathumbnails {
  display: flex !important;
  height: 100% !important;
}
.entry-item-dialog .p-galleria-thumbnail-container {
  background: unset !important;
  height: 100% !important;
  padding: 0 !important;
}
.entry-item-dialog .thumbnail-image {
  height: 6.375rem;
  width: 6rem;
}
.entry-item-dialog .p-galleria-thumbnail-item-content {
  height: 6rem;
}
.entry-item-dialog .p-galleria-thumbnail-prev,
.entry-item-dialog .p-galleria-thumbnail-next {
  color: #0b0c0c !important;
}
.entry-item-dialog .p-galleria-thumbnail-wrapper {
  padding: 0 !important;
}

/*****************************************************
	Phone Number Input styling below
*****************************************************/
.iti input {
  border: 1px solid #9e9e9e !important;
  height: 56px !important;
}

.iti {
  margin-top: 2px !important;
  width: 100%;
}
.iti.iti--container {
  z-index: 4000;
  max-width: 400px;
}
.iti .dropdown-menu.country-dropdown {
  font-size: 1.125rem;
  border-color: #c7cace;
  max-width: 400px;
}
.iti .iti__country-list {
  overflow-x: hidden;
  width: 100%;
  max-height: 220px;
}
@media screen and (max-width: 479px) {
  .iti .iti__country-list {
    width: 88.3vw;
  }
}
.iti .iti__country {
  padding: 1rem;
}

ngx-intl-tel-input input {
  width: 100%;
  height: 3.875rem;
  padding: 10px;
  border: 1px solid;
  border-color: #e56000;
  border-radius: 6px;
  font-size: 1.125rem;
}
ngx-intl-tel-input input[disabled] {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.38);
}
ngx-intl-tel-input.ng-invalid.ng-touched input {
  border-color: var(--red-600) !important;
}

.submissions-dashboard-container .submissions-dashboard-router-container > :nth-child(2) {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.submissions-dashboard-container .submissions-dashboard-router-container .message-no-spacing .p-message {
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 0 !important;
}
.submissions-dashboard-container .submissions-dashboard-router-container .p-paginator {
  background-color: #f8f8f8 !important;
  display: flex;
  justify-content: flex-end;
}
.submissions-dashboard-container .submissions-dashboard-router-container .form-response-answer-rows-selected {
  background-color: #fff3e6 !important;
}
.submissions-dashboard-container .sidebar-button .p-button.p-button-icon-only {
  width: 1.75rem !important;
  height: 1.75rem !important;
  padding: 0 !important;
}

.pathway-icon {
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 50%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem !important;
}

.pathway-item {
  position: relative;
}
.pathway-item:focus {
  border: 3px solid #1a66a8 !important;
  box-shadow:
    0px 1px 8px rgba(0, 0, 0, 0.08),
    0px 3px 4px rgba(0, 0, 0, 0.1),
    0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
}

nav .p-button-nav button {
  padding: 0.75rem 0.5rem !important;
  font-size: 0.875rem !important;
}
nav .nav-active button {
  box-shadow: inset 0 -2px 0 0 #e56000 !important;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.isla-card {
  padding: 1.5rem;
  border-radius: 6px;
  border: 1px solid #d3d6d7;
  background-color: #fcfcfc;
}
.isla-card h5,
.isla-card h4,
.isla-card h3,
.isla-card h2,
.isla-card h1 {
  margin-top: 0;
}

/* Content */
.text-main {
  color: #0b0c0c !important;
}

.text-secondary {
  color: #505a5f !important;
}

.text-disabled {
  color: #666f73 !important;
}

.text-link {
  color: #1a66a8 !important;
}

.text-link:hover {
  color: #003078 !important;
}

.text-link:visited {
  color: #4c2c92 !important;
}

.text-error {
  color: #c23019 !important;
}

.text-warning {
  color: #897306 !important;
}

.text-success {
  color: #35824f !important;
}

.text-brand {
  color: #ef6706 !important;
}

.text-main-inverse {
  color: #fcfcfc !important;
}

.text-secondary-inverse {
  color: #f3f2f1 !important;
}

.text-overlay-subtle {
  color: rgba(124, 131, 135, 0.5) !important;
}

.text-pink {
  color: #f13ca9 !important;
}

.text-pink-subtle {
  color: #ffdcf5 !important;
}

.text-purple {
  color: #7b61ff !important;
}

.text-purple-subtle {
  color: #ebe7ff !important;
}

/* Borders */
.border-main {
  border-color: #d3d6d7 !important;
}

.border-alt {
  border-color: #a7acaf !important;
}

.border-alt-2 {
  border-color: #7c8387 !important;
}

.border-brand {
  border-color: #e56000 !important;
}

.border-brand-subtle {
  border-color: #ffbf7f !important;
}

.border-error {
  border-color: #c23019 !important;
}

.border-warning {
  border-color: #f9dd4f !important;
}

.border-success {
  border-color: #35824f !important;
}

.border-overlay-subtle {
  border-color: rgba(124, 131, 135, 0.5) !important;
}

.border-primary {
  border-color: #e56000;
}

/* Backgrounds */
.bg-surface-white {
  background-color: #fff !important;
}

.bg-main {
  background-color: #fcfcfc !important;
}

.bg-main-surface {
  background-color: #f8f8f8 !important;
}

.bg-main-surface-alt {
  background-color: #f3f2f1 !important;
}

.bg-inverse-main {
  background-color: #2e3336 !important;
}

.bg-inverse-main-surface {
  background-color: #1d2021 !important;
}

.bg-inverse-main-surface-alt {
  background-color: #0b0c0c !important;
}

.bg-overlay-subtle {
  background-color: rgba(124, 131, 135, 0.5) !important;
}

.bg-brand-subtle {
  background-color: #ffbf7f !important;
}

.bg-brand-subtle-alt {
  background-color: #fff3e6 !important;
}

/* Element Backgrounds */
.el-bg-disabled {
  background-color: #d3d6d7 !important;
}

.el-bg-primary {
  background-color: #e56000 !important;
}

.el-bg-primary:hover {
  background-color: #cc5600 !important;
}

.el-bg-primary:active {
  background-color: #b34b00 !important;
}

.el-bg-primary-subtle {
  background-color: #ffe5cc !important;
}

.el-bg-primary-subtle-alt {
  background-color: #fff3e6 !important;
}

.el-bg-primary-light {
  background-color: #ffbf7f !important;
}

.el-bg-action {
  background-color: #1a66a8 !important;
}

.el-bg-action:hover {
  background-color: #003078 !important;
}

.el-bg-action:active {
  background-color: #002256 !important;
}

.el-bg-action-subtle {
  background-color: #cce5ff !important;
}

.el-bg-action-subtle-alt {
  background-color: #e5f2ff !important;
}

.el-bg-error {
  background-color: #c23019 !important;
}

.el-bg-error:hover {
  background-color: #9f2815 !important;
}

.el-bg-error:active {
  background-color: #6a1b0e !important;
}

.el-bg-error-subtle {
  background-color: #ffdfdf !important;
}

.el-bg-error-subtle-alt {
  background-color: #ffecec !important;
}

.el-bg-warning-subtle {
  background-color: #ffffcc !important;
}

.el-bg-warning-subtle-alt {
  background-color: #ffffe5 !important;
}

.el-bg-success {
  background-color: #35824f !important;
}

.el-bg-success:hover {
  background-color: #255a37 !important;
}

.el-bg-success:active {
  background-color: #132d1b !important;
}

.el-bg-success-subtle {
  background-color: #ccffdd !important;
}

.el-bg-success-subtle-alt {
  background-color: #e5ffee !important;
}

.el-bg-pink {
  background-color: #f13ca9 !important;
}

.el-bg-pink-subtle {
  background-color: #ffdcf5 !important;
}

.el-bg-purple {
  background-color: #7b61ff !important;
}

.el-bg-purple-subtle {
  background-color: #ebe7ff !important;
}

/*# sourceMappingURL=theme.css.map */
